export const isRealString = (string: string | number) => {
  if (typeof string === 'string') {
    return true;
  }

  return false;
};

export const replaceRomanNumberUnicodeToAlphabet = (str: string) => {
  //Ⅰ Ⅱ Ⅲ Ⅳ Ⅴ Ⅵ Ⅶ Ⅷ Ⅸ Ⅹ Ⅺ Ⅻ Ⅼ Ⅽ Ⅾ Ⅿ ⅰ ⅱ ⅲ ⅳ ⅴ ⅵ ⅶ ⅷ ⅸ ⅹ ⅺ ⅻ ⅼ ⅽ ⅾ ⅿ

  // 특수 로마 숫자와 그에 해당하는 ASCII 코드의 로마 숫자 매핑
  const symbolMap = {
    'Ⅰ': 'I', 'Ⅱ': 'II', 'Ⅲ': 'III', 'Ⅳ': 'IV', 'Ⅴ': 'V', 
    'Ⅵ': 'VI', 'Ⅶ': 'VII', 'Ⅷ': 'VIII', 'Ⅸ': 'IX', 'Ⅹ': 'X', 
    'Ⅺ': 'XI', 'Ⅻ': 'XII', 'Ⅼ': 'L', 'Ⅽ': 'C', 'Ⅾ': 'D', 
    'Ⅿ': 'M', 'ⅰ': 'i', 'ⅱ': 'ii', 'ⅲ': 'iii', 'ⅳ': 'iv', 
    'ⅴ': 'v', 'ⅵ': 'vi', 'ⅶ': 'vii', 'ⅷ': 'viii', 'ⅸ': 'ix', 
    'ⅹ': 'x', 'ⅺ': 'xi', 'ⅻ': 'xii', 'ⅼ': 'l', 'ⅽ': 'c', 
    'ⅾ': 'd', 'ⅿ': 'm'
  };

  // 문자열에서 특수 기호를 찾아 ASCII 코드의 로마 숫자로 교체
  for (const [symbol, ascii] of Object.entries(symbolMap)) {
    str = str.replaceAll(symbol, ascii);
  }

  return str;
}