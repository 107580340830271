import { useEffect } from 'react';
/**
 * 특정 영역을 제외한 외부 클릭 감지
 */

export const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: (e: React.MouseEvent) => void
) => {
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClickOutside(e);
      }
    };
    document.addEventListener('mousedown', handleClickOutside); // bind
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // dispose
    };
  }, [ref, onClickOutside]);
};
