import { NULLABLE } from '@ts-types/common';
import { AUTH_TYPE } from '@ts-types/auth';
import create from 'zustand';
import { persist } from 'zustand/middleware';

type USER = {
  id: NULLABLE<number>;
  name: string;
  remainTicketCount: number;
  searchHistory: any;
  token: string;
  type: AUTH_TYPE;
};

interface USER_STORE extends USER {
  setValue: (key: string, arg: any) => void;
  reset: () => void;
  saveUser: (arg: USER) => void;
}

const useUserStore = create<USER_STORE>()(
  persist(
    (set) => ({
      id: null,
      name: '',
      remainTicketCount: 0,
      searchHistory: [],
      token: '',
      type: 'USER',
      saveUser: (payload: USER) => {
        set(payload);
      },
      setValue: (key, val) => {
        set({ [key]: val });
      },
      reset: () => {
        set({
          id: null,
          name: '',
          remainTicketCount: 0,
          searchHistory: [],
          token: '',
          type: 'USER',
        });
      },
    }),
    {
      name: 'user-storage',
    }
  )
);

export default useUserStore;
