import React, {
  useRef,
  useState,
  useEffect,
  useDeferredValue,
  useMemo,
} from 'react';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import useInput from '@service/hooks/useInput';
import { carNumberRegExe } from '@service/utils/regExp';
import { isEmpty } from '@service/utils/validation';
import Toast from '@components/common/Toast';
import Input from '@components/common/Input';
import { NotoBold, NotoMedium, NotoRegular } from '@styles/global';
import { FlexStyle } from '@styles/carFaxResultStyle';
import { useRouter } from 'next/router';
import useUserStore from '@store/userStore';
import Modal from '@components/common/Modal';
import { ErrorWrapper, Message } from '@pages/result';
import { CDN } from '@service/constants/urls';

interface TOAST_TYPE {
  message: string;
  icon: string;
}

const InitialToastDetail = {
  message: '',
  icon: '',
};

interface TestCarNumberInputProps {
  isMobile?: boolean;
}

const TestCarNumberInput = ({ isMobile = false }: TestCarNumberInputProps) => {
  const router = useRouter();

  const ticketModalRef = useRef<HTMLInputElement>(null);
  const loginModalRef = useRef<HTMLInputElement>(null);
  const registerNumberRef = useRef<HTMLInputElement>(null);
  const toastRef = useRef<HTMLInputElement>(null);

  const curEnv = process.env.NEXT_PUBLIC_ENV || 'production';

  /** 서비스 점검중 팝업을 띄워야 할 때  isHold = true */
  const serviceAlertRef = useRef<HTMLInputElement>(null);
  const isHold = false;
  /** */

  // const getMaintenance = () => {
  //   if (dayjs().month() === 2 && dayjs().date() === 31) {
  //     const hour = dayjs().hour();
  //     if (hour >= 18 && hour <= 23) {
  //       return true;
  //     } else return false;
  //   }
  //   return false;
  // };

  const [toastDetail, setToastDetail] = useState<TOAST_TYPE>(InitialToastDetail);

  const {
    value: registerNumber,
    onChangeInput: onChangeRegisterNumber,
    isError: carNumberError,
    isHasError: checkCarNum,
    reset: resetCarNum,
  } = useInput({
    maxLength: 11,
    initialValue: isMobile ? router.query?.no : '',
    regExeForValidationCheck: carNumberRegExe,
    noSpace: true,
  });

  const { token, remainTicketCount } = useUserStore();

  const isLoggedOn = !isEmpty(token);

  const isValid = !isEmpty(registerNumber);

  const onClickSearch = () => {
    // if (getMaintenance() && !isEmpty(name))
    //   return serviceAlertRef?.current.open();
    if (isHold) return serviceAlertRef?.current.open();

    const isError = checkCarNum();

    if (!isLoggedOn) {
      loginModalRef.current?.open();
    } else {
      if (remainTicketCount > 0) {
        if (isError) {
          setToastDetail({
            message: '입력 정보를 다시 확인해주세요.',
            icon: 'caution',
          });
          toastRef?.current?.open();
          return;
        } else {
          router.push(`/result?no=${registerNumber}&name=${name}`);
          // router.push(`/result?no=${registerNumber}`);

          if (!isMobile)
            setTimeout(() => {
              resetCarNum();
            }, 1000);
        }
      } else {
        ticketModalRef.current.open();
      }
    }
  };

  useEffect(() => {
    const pressKey = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onClickSearch();
      }
    };
    document.addEventListener('keydown', pressKey);

    return () => {
      document.removeEventListener('keydown', pressKey);
    };
  }, [isValid, registerNumber]);

  return (
    <>
      {isMobile && (
        <MobileTitle>
          <p className='title'>차량번호를 입력하세요.</p>
          <p className='sub-title'>입력하신 후 검색버튼을 클릭하세요.</p>
        </MobileTitle>
      )}
      <Flex isMobile={isMobile}>
        <InputWrapper isMobile={isMobile}>
          {isMobile && <Label>차량번호</Label>}
          <Input
            ref={registerNumberRef}
            value={registerNumber}
            onChange={onChangeRegisterNumber}
            type='text'
            placeholder={isMobile ? '' : '차량번호'}
            errorMessage='형식을 확인해 주세요'
            isError={carNumberError}
            maxLength={9}
          />
        </InputWrapper>

        {!isMobile ? (
          <Search disabled={isEmpty(registerNumber)} onClick={onClickSearch}>
            <Image
              src={`${CDN}icons/pictogram-regular-search_gray.png`}
              alt='search'
              width={36}
              height={36}
              style={{
                objectFit: 'contain',
              }}
            />
          </Search>
        ) : (
          <MobileSearch disabled={isEmpty(registerNumber)} onClick={onClickSearch}>
            <h5>검색</h5>
          </MobileSearch>
        )}
      </Flex>

      <Toast
        message={toastDetail?.message}
        icon={toastDetail?.icon}
        ref={toastRef}
      />

      <Modal
        ref={ticketModalRef}
        leftButtonTitle='확인'
        leftButtonPressed={() => router.replace('/')}
      >
        <ErrorWrapper>
          <Message>
            조회권이 모두 소진되었습니다. <br />
            새로운 조회권을 구매해주세요.
          </Message>
        </ErrorWrapper>
      </Modal>

      <Modal
        ref={loginModalRef}
        leftButtonTitle='취소'
        rightButtonTitle='확인'
        leftButtonPressed={() => router.replace('/')}
        rightButtonPressed={() => router.replace('/login')}
      >
        <ErrorWrapper>
          <Message>
            로그인 후 이용 가능합니다. <br />
            로그인 페이지로 이동하시겠습니까?
          </Message>
        </ErrorWrapper>
      </Modal>

      <Modal ref={serviceAlertRef} leftButtonTitle='확인'>
        <ErrorWrapper>
          <Message>서비스 점검 중입니다.</Message>
        </ErrorWrapper>
      </Modal>
    </>
  );
};
export default TestCarNumberInput;

const MobileTitle = styled.div`
  text-align: center;
  padding-top: 56px;
  margin-bottom: 30px;

  .title {
    ${NotoRegular}
    color:#535353;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .sub-title {
    ${NotoRegular}
    color:#A0A0A0;
    font-size: 12px;
    line-height: 16px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      margin: 0 30px;
    `}
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 56px;
  width: 254px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      margin-bottom: 16px;
      position: relative;
    `}

  input {
    text-align: center;
    height: 56px;
    width: 100%;
    font-size: 20px;
    ${NotoBold}

    ::placeholder {
      ${NotoRegular}
      font-size: 20px;
      width: 100%;
      color: #c6c6c6;
      text-align: center;
    }

    ${({ isMobile }) =>
      isMobile &&
      css`
        padding-left: 20px;
        text-align: left;
        border: 1px solid #cbcbcb;
        ${NotoMedium}
        color:#171717;
        font-size: 14px;

        ::placeholder {
          ${NotoMedium}
          color:#C6C6C6;
          font-size: 14px;
          text-align: left;
        }
      `}
  }
`;

const Label = styled.p`
  ${NotoRegular}
  font-size:12px;
  position: absolute;
  top: -10px;
  left: 13px;
  z-index: 1;
  color: #aeaeae;
  background-color: #ffffff;
`;

const Search = styled.button`
  ${FlexStyle}
  justify-content: center;
  cursor: pointer;
  background-color: #121212;
  width: 56px;
  height: 56px;
`;

const MobileSearch = styled.button`
  width: 100%;
  height: 52px;
  background-color: #1ac7ff;
  ${FlexStyle}
  justify-content: center;

  &:disabled {
    background-color: #e0e0e0;
  }

  h5 {
    ${NotoBold}
    font-size:14px;
    color: #ffffff;
  }
`;
