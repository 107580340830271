import { isEmpty } from './validation';

export const convertNumToKrw = (num: number) => {
  if (!num) return null;
  const len = num.toString().length;
  const sliceNum = num.toString().slice(0, len - 4);
  return addComma(+sliceNum);
};

/**
 * 숫자에 3자리마다 ','를 붙힙니다.
 * @param number
 * @returns
 */
export const addComma = (number: number | string) => {
  if (isEmpty(number)) return '';
  if (String(number)?.includes(',')) return number;
  else return Number(number).toLocaleString();
};
