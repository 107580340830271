import create from 'zustand';

interface TAGS {
  [key: string]: string[];
}

interface HISTORY_CAR {
  carNo: string;
  created: string;
  ownerName: string;
}
interface SEARCH_STORE {
  tags: TAGS;
  selectedCar: HISTORY_CAR;
  transactionList: TAGS;
  setValue: (key: string, arg: any) => void;
}

const useSearchStore = create<SEARCH_STORE>((set) => ({
  tags: {},
  selectedCar: { carNo: '', created: '', ownerName: '' },
  transactionList: {},
  setValue: (key, val) => {
    set({ [key]: val });
  },
}));

export default useSearchStore;
