import Router from 'next/router';
import axios from 'axios';
import { env, newServerUrls } from './urls';
import useUserStore from '@store/userStore';
import { isEmpty } from '@service/utils/validation';

const ApiClient = axios.create({
  baseURL: newServerUrls[env],
  // timeout: 10 * SECOND_TO_MS,
});

// ApiClient.interceptors.request.use(
//   (_config) => {
//     const config = _config;

//     // const { token } = useUserStore();

//     // if (!isEmpty(token)) {
//     //   config.headers = {
//     //     ...config.headers,
//     //     'x-cf-access-token': token,
//     //   };
//     // }

//     return config;
//   },
//   (err) => {
//     console.error(err);
//   }
// );

export default ApiClient;
