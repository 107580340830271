import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import copy from 'copy-to-clipboard';
import CarSeizure from './CarSeizure';
import CarInsurance from './CarInsurance';
import { useRouter } from 'next/router';
import { useIsMobile } from '@service/hooks/useIsMobile';
import {
  BorderLine, Flex, FlexStyle, MaxWidth, MoreBtn,
  MoreBtnWrapper,
} from '@styles/carFaxResultStyle';
import { NotoBold, NotoRegular, R14, RegularStyle } from '@styles/global';
import BriefInfo from '@components/section/BriefInfo';
import { getWindowDimension } from '@service/utils/getWindowDimestion';
import Spec from '@components/section/Spec';
import PriceOption from '@components/section/PriceOption';
import Loading from '@components/common/Loading';
import MobileSpec from '@components/section/MobileSpec';
import MobilePriceOption from '@components/section/MobilePriceOption';
import MobileCarSeizure from './MobileCarSeizure';
import MobileCarInsurance from './MobileCarInsurance';
import Toast from '@components/common/Toast';
import FilteredHiddenHistory from '@components/section/FilteredHiddenHistory';

import useUserStore from '@store/userStore';
import {
  useRecordSummary,
  useCheckSpec,
} from '@service/query/useSearch';

export const getFirstRegDate = (date: string) => {
  if (!isEmpty(date)) {
    if (date?.includes('-')) {
      return {
        yyyy: date?.slice(0, 4),
        mm: date?.slice(5, 7),
        dd: date?.slice(7),
      };
    } else {
      return {
        yyyy: date?.slice(0, 4),
        mm: date?.slice(4, 6),
        dd: date?.slice(6),
      };
    }
  } else return { yyyy: '', mm: '', dd: '' };
};

interface CarDetailContentProps {
  transactionId: string;
}

export interface ContentProps extends CarDetailContentProps {
  carNo: string;
  ownerName: string;
  isMobile?: boolean;
}

export interface SpecProps {
  specData?: any;
  isSpecRefetching?: boolean;
  odometer?: number;
}

const CarDetailContent = ({ transactionId }: CarDetailContentProps) => {
  const router = useRouter();
  const { width } = getWindowDimension();
  const { token } = useUserStore();

  const carNo: string = router.query?.no;
  const ownerName: string = router.query?.name;
  const isMobile = useIsMobile();
  const toastRef = useRef<HTMLInputElement>(null);
  const insuranceRef = useRef<HTMLInputElement>(null);

  const [showInsurance, setShowInsurance] = useState<boolean>(false);
  const [ odometer, setOdometer ] = useState<number>(0);

  const curEnv = process.env.NEXT_PUBLIC_ENV === 'production' ? 'pro' : 'dev';

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: recordData, isLoading: isRecordLoading } = useRecordSummary({
    carNo,
    ownerName,
    token,
    transactionId,
  });

  const { data: specData, isLoading: isSpecLoading, error: specError, isRefetching: isSpecRefetching } = useCheckSpec({
    carNo,
    ownerName,
    token,
    transactionId,
  });

  const showInsuranceHandler = () => {
    setShowInsurance(!showInsurance);
  }
  useEffect(() => {
    if (showInsurance && insuranceRef.current) {
      insuranceRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
    }
  }, [showInsurance]);

  useEffect(() => {
    if (showInsurance) {
      setShowInsurance(false);
    }
    setOdometer(0);
  }, [carNo, ownerName, transactionId]);

  const reloadData = () => {
    // 추후 기능 추가. 현재는 로딩되는 UI만
    setIsLoading(true);
    setShowInsurance(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  if (!isEmpty(carNo) && !isEmpty(transactionId))
    return (
      <Container isMobile={isMobile} w={width}>
        {isLoading && <Loading />}
        {!isMobile && (
          <>
            <Flex apart>
              <PrintButton>
                <p>인쇄하기</p>
              </PrintButton>
              {/* 공유하기 버튼 임시 삭제 */}
              {/* <Icon
                name='pictogram-regular-share_header'
                width={36}
                height={36}
                onClick={() => {
                  copy(window.location.href);
                  toastRef?.current?.open();
                }}
              /> */}
            </Flex>

            <CarNum>
              <p>차량번호</p>
              <h3>{carNo}</h3>
            </CarNum>
          </>
        )}

        <BriefInfo
          carNo={carNo}
          isMobile={isMobile}
          specData={specData}
          specError={specError}
        />

        {/* 보험이력 보기 클릭한 경우, mileage 정보 전달 */}
        {isMobile ? (
          <MobileSpec
            specData={specData}
            isSpecRefetching={isSpecRefetching}
            odometer={odometer}
          />
        ) : (
          <Spec
            specData={specData}
            isSpecRefetching={isSpecRefetching}
            odometer={odometer}
          />
        )}

        {isMobile ? (
          <MobilePriceOption
            specData={specData}
            isSpecLoading={isSpecLoading}
          />
        ) : (
          <PriceOption
            specData={specData}
            isSpecLoading={isSpecLoading}
          />
        )}

        {!isEmpty(ownerName) &&
          (isMobile ? (
            <MobileCarSeizure
              carNo={carNo}
              ownerName={ownerName}
              transactionId={transactionId}
            />
          ) : (
            <CarSeizure
              carNo={carNo}
              ownerName={ownerName}
              transactionId={transactionId}
            />
          ))}

        <FilteredHiddenHistory
          carNo={carNo}
          ownerName={ownerName}
          transactionId={transactionId}
          isMobile={isMobile}
          showInsurance={showInsurance}
          recordData={recordData}
          specData={specData}
          isRecordLoading={isRecordLoading}
        />

        <div ref={insuranceRef}>
          {showInsurance && (<>
            {
              isMobile ? (
                <MobileCarInsurance
                  carNo={carNo}
                  ownerName={ownerName}
                  transactionId={transactionId}
                  setOdometer={setOdometer}
                />
              ) : (
                <CarInsurance
                  carNo={carNo}
                  ownerName={ownerName}
                  transactionId={transactionId}
                  setOdometer={setOdometer}
                />
              )}
          </>)}
        </div>
        <InsuranceBtn isMobile={isMobile}>
          <InsuranceMoreBtn showInsurance={showInsurance} onClick={showInsuranceHandler}>
            <h5>{showInsurance ? '보험이력 숨기기' : '보험이력 보기'}</h5>
          </InsuranceMoreBtn>
        </InsuranceBtn>
        <BorderLine />
        {isMobile ? (
          <MobileReload>
            <div className='guide'>
              <p>차량정보가 다를 때 재조회하세요.</p>
              <p>※재조회시 원부료가 과금됩니다.</p>
            </div>
            <MobileRelodBtn onClick={reloadData}>
              <p>재조회</p>
            </MobileRelodBtn>
          </MobileReload>
        ) : (
          <Reload>
            <FlexEnd>
              <p>
                차량정보가 다를 때 재조회하세요. ※재조회시 원부료가 과금됩니다.
              </p>
              <ReloadBtn onClick={reloadData}>
                <p>재조회</p>
              </ReloadBtn>
            </FlexEnd>
          </Reload>
        )}

        <Toast message='페이지 주소가 복사되었습니다.' ref={toastRef} />
      </Container>
    );
  return <Container />;
};

export default CarDetailContent;

const Container = styled.div`
  padding-top: 30px;
  width: ${({ w }) => (w > MaxWidth ? MaxWidth : w)}px;
  background: ${({ theme }) => theme.base.white};
`;

const PrintButton = styled.button`
  height: 42px;
  width: 157px;
  background-color: #999999;
  border-radius: 2px;
  ${FlexStyle}
  justify-content: center;
  margin-bottom: 30px;

  p {
    ${NotoRegular}
    font-size:14px;
    color: #ffffff;
  }
`;

const CarNum = styled.div`
  width: 312px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  ${FlexStyle}
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 30px;

  p {
    ${NotoRegular}
    color: #7c7c7c;
    font-size: 20px;
  }

  h3 {
    ${NotoBold}
    font-size: 28px;
  }
`;

const Reload = styled.div`
  height: 70px;
  width: 100%;
  background-color: #f7f7f7;
  margin-bottom: 170px;
  padding: 16px 40px;

  p {
    ${NotoRegular}
    font-size:13px;
  }
`;

const MobileReload = styled(Reload)`
  height: 230px;
  ${FlexStyle}
  flex-direction: column;

  .guide {
    margin-top: 33px;
    margin-bottom: 16px;
    p {
      ${NotoRegular}
      font-size:13px;
      line-height: 20px;
      text-align: center;
    }
  }
`;

const FlexEnd = styled.div`
  ${FlexStyle}
  justify-content: flex-end;
`;

const ReloadBtn = styled.button`
  width: 74px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid #efefef;
  ${FlexStyle}
  justify-content: center;
  margin-left: 24px;
  cursor: pointer;

  p {
    ${NotoRegular}
    font-size:13px;
  }
`;

const MobileRelodBtn = styled(ReloadBtn)`
  width: 130px;
`;

const InsuranceBtn = styled(MoreBtnWrapper)`
  padding: 0 0 40px 0;
  padding-top: ${({ isMobile }) => (isMobile ? '40px' : '0')};
  `;

const InsuranceMoreBtn = styled(MoreBtn)`

`;  