import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import styled, { css } from 'styled-components';
import { NotoBold, NotoMedium, NotoRegular } from '@styles/global';
import {
    BorderLine, MobileSection, Section, MoreBtnWrapper,
    MoreBtn,
} from '@styles/carFaxResultStyle';
import KencisApiClient, {
    SEARCH_CUSTOMS_CLEARANCE_INFO_PATH,
} from '@api/KencisApiClient';
import Skeleton from '@components/common/Skeleton';
import { ContentProps } from '@components/carfax/CarDetailContent';
import { getWindowDimension } from '@service/utils/getWindowDimestion';
import { decodeVinPlantsCode } from '@service/utils/decodeVinPlantsCode';

interface FilteredProps extends ContentProps {
    showInsurance: boolean;
    specData?: any;
    recordData?: any;
    isRecordLoading?: boolean;
}

const FilteredHiddenHistory = ({
    carNo,
    ownerName,
    transactionId,
    isMobile,
    showInsurance,
    specData,
    recordData,
    isRecordLoading
}: FilteredProps) => {
    const { width } = getWindowDimension();

    const [showAll, setShowAll] = useState<boolean>(false);
    const [fullData, setFullData] = useState<any>([]);
    const historyRef = useRef<any>(null);

    // 통관 정보 관련
    const [isCustomsClearanceLoading, setIsCustomsClearanceLoading] =
        useState(false);
    const [clearanceData, setClearanceData] = useState<any>([]);
    const [vinHistory, setVinHistory] = useState('' as string);
    const [importYnHistory, setImportYnHistory] = useState('' as string);

    const historyScrollHandler = () => {
        setShowAll(!showAll);
    }
    useEffect(() => {
        if (showAll && historyRef.current) {
            historyRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
        }
    }, [showAll]);

    useEffect(() => {
        if (showAll) {
            setShowAll(false);
        }
    }, [carNo, ownerName, transactionId])

    // ---- 통관 정보 조회 및 처리
    const extractManufacturer = (data: string) => {
        const regex = /제작사명<\/th>\s*<td>([^<]+)<\/td>/;
        const match = regex.exec(data);

        if (match && match[1]) {
            return match[1];
        } else {
            return '';
        }
    };

    const extractImporter = (data: string) => {
        const regex = /수입사명<\/th>\s*<td>([^<]+)<\/td>/;
        const match = regex.exec(data);

        if (match && match[1]) {
            return match[1];
        } else {
            return '';
        }
    };

    const extractCustomsClearanceDate = (data: string) => {
        const regex = /통관일자<\/th>\s*<td>([^<]+)<\/td>/;
        const match = regex.exec(data);

        if (match && match[1]) {
            return match[1];
        } else {
            return '';
        }
    };

    const fetchCustomsClearanceData = async (vin: string) => {
        try {
            let form = new FormData();
            form.append('searchKeyword', vin);

            const res = await KencisApiClient.post(
                `${SEARCH_CUSTOMS_CLEARANCE_INFO_PATH}`,
                form,
            );

            const resHtml = res?.data.toString();

            const manufacturer = extractManufacturer(resHtml);
            const importer = extractImporter(resHtml);
            const customsClearanceDate = extractCustomsClearanceDate(resHtml);

            if (manufacturer !== '' && importer !== '' && customsClearanceDate !== '') {
                const customsClearanceItem = {
                    summary: '수입통관',
                    category: 'CLEARANCE',
                    categoryName: '통관',
                    manufacturer: manufacturer,
                    importer: importer,
                    customsClearanceDate: customsClearanceDate,
                    content: `수입통관 \n제작사 : ${manufacturer}\n수입사명 : ${importer}\n통관일자 : ${customsClearanceDate}`,
                    recordAt: customsClearanceDate,
                    vin: vin,
                };

                setClearanceData(customsClearanceItem);
            } else {
                setClearanceData({});
            }

            setIsCustomsClearanceLoading(false);

            return res?.data;
        } catch (err) {
            console.log('customs clearance api error : ', err);
            setIsCustomsClearanceLoading(false);
            return Promise.reject(err);
        }
    };

    const addCustomsClearanceInfo = (vin: string, importYn: string) => {
        if (
            (importYn !== importYnHistory || vin !== vinHistory) &&
            importYn === 'Y' &&
            vin.length > 0
        ) {
            setIsCustomsClearanceLoading(true);

            setImportYnHistory(importYn);
            setVinHistory(vin);

            fetchCustomsClearanceData(vin);
        }
    };


    useEffect(() => {
        let total = [];
        const brandName = specData?.carSpec?.brandName;

        /* 국산/수입 여부 확인 */
        const importYn =
            brandName &&
                !brandName?.includes('현대') &&
                !brandName?.includes('기아') &&
                !brandName?.includes('제네시스') &&
                !brandName?.includes('르노(삼성)') &&
                !brandName?.includes('쉐보레(대우)') &&
                !brandName?.includes('KG모빌리티(쌍용)')
                ? 'Y'
                : 'N';

        /* 차대번호 확인 */
        const vin = specData?.carSpec?.vin ?? '';

        /* 통관정보 추가 */
        addCustomsClearanceInfo(vin, importYn);
        // clearanceData에 추가됨. 

        /* 생산공장 정보 추가 */
        const plant = decodeVinPlantsCode(vin, brandName);

        if (plant) {
            total.unshift({
                summary: `${plant} 공장에서 생산`,
                categoryName: '생산',
                category: 'PRODUCTION',
                content: '',
                recordAt:
                    clearanceData?.customsClearanceDate || '',
            });
        }

        if (
            clearanceData?.content &&
            clearanceData?.vin === vin
        ) {
            // 전체 데이터에 추가
            total.push(clearanceData);
        }

        if (recordData) {
            total = total.concat(recordData);
        }

        setFullData(total);
    }, [specData, recordData, clearanceData])
    // ---------------------------- 통관 정보 처리 완료


    const formatContent = (content: any) => {
        return { __html: content?.replace(/\n/g, '<br>') };
    };

    const Wrapper = ({ children }) =>
        isMobile ? (
            <MobileSection>{children}</MobileSection>
        ) : (
            <Section>{children}</Section>
        );

    const filteredData = fullData?.filter((item) => item.category !== 'ACCIDENT');
    const dataToDisplay = showInsurance ? fullData : filteredData;

    return (<>
        <Wrapper>
            <SectionTitle ref={historyRef}>차량 숨은 이력</SectionTitle>
            <BlackBorderLine isMobile={isMobile} />
            <HistoryWrapper isMobile={isMobile} w={width}>
                {isCustomsClearanceLoading && (<NoHistory>
                    <p>통관 정보를 불러오는 중입니다.</p>
                </NoHistory>)}

                {!dataToDisplay || dataToDisplay.length === 0 ? (
                    <NoHistory><p>이력이 없습니다.</p></NoHistory>
                ) : (<>
                    {isRecordLoading ? (<>
                        <Skeleton width='100%' height={120} />
                    </>) : (
                        <>
                            {dataToDisplay.length <= 2 ? (
                                dataToDisplay.map((history, index) => (
                                    <HistoryContent key={index}>
                                        <HistoryTitleContainer>
                                            {history.recordAt && (
                                                <div>
                                                    <p>{dayjs(history.recordAt).year()}년</p>
                                                    <p>{dayjs(history.recordAt).month() + 1}월</p>
                                                </div>
                                            )}
                                            <div>
                                                <h5>{history.categoryName}</h5>
                                            </div>
                                        </HistoryTitleContainer>
                                        <HistoryDetailContainer category={history.category}>
                                            <h6>{history.summary}</h6>
                                            <div dangerouslySetInnerHTML={formatContent(history.content)} />
                                        </HistoryDetailContainer>
                                    </HistoryContent>
                                ))
                            ) : (
                                <>
                                    {dataToDisplay.slice(0, showAll ? dataToDisplay.length : 2).map((history, index) => (
                                        <HistoryContent key={index}>
                                            <HistoryTitleContainer>
                                                {history.recordAt && (
                                                    <div>
                                                        <p>{dayjs(history.recordAt).year()}년</p>
                                                        <p>{dayjs(history.recordAt).month() + 1}월</p>
                                                    </div>
                                                )}
                                                <div>
                                                    <h5>{history.categoryName}</h5>
                                                </div>
                                            </HistoryTitleContainer>
                                            <HistoryDetailContainer category={history.category}>
                                                <h6>{history.summary}</h6>
                                                <div dangerouslySetInnerHTML={formatContent(history.content)} />
                                            </HistoryDetailContainer>
                                        </HistoryContent>
                                    ))}
                                    <HistoryButtonWrapper>
                                        <MoreBtn onClick={historyScrollHandler}>
                                            <h5>{showAll ? '이력 접기' : '이력 전체 보기'}</h5>
                                        </MoreBtn>
                                    </HistoryButtonWrapper>
                                </>
                            )}
                        </>
                    )}
                </>)}
            </HistoryWrapper>
        </Wrapper >
    </>)
}

export default FilteredHiddenHistory;


const SectionTitle = styled.p`
  ${NotoRegular}
  font-size:17px;
  letter-spacing: -2%;
  color: #757575;
  margin-bottom: 12px;

  .red {
    ${NotoRegular}
    font-size:17px;
    color: ${({ theme }) => theme.primary};
  }

  .bold {
    ${NotoBold}
    color:#121212;
  }
`;

export const BlackBorderLine = styled(BorderLine)`
  background-color: #232323;
  margin-bottom: 32px;
  ${({ isMobile }) =>
        isMobile &&
        css`
        margin-bottom: 16px;
`}
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dddde1;

  ${({ isMobile, w }) =>
        isMobile &&
        css`
      max-width: ${w}px;
      padding: 0;
      margin: 0;
      border: none;
    `}
`;

const HistoryContent = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
  padding: 16px;
  background: #ffffff;
`;

const HistoryTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 0.7;
  p {
    font-size: 14px;
    color: #5c5c5c;
  }
  h5 {
    font-size: 16px;
  }
`;

const BG_BLUE = '#d5f5ff';
const BG_RED = '#f2dddd';
const BG_GREEN = '#d5efd1';

const HistoryDetailContainer = styled.div`
    background: ${({ category }) => {
        if (category === 'ACCIDENT') {
            return BG_RED;
        } else if (category === 'PRODUCTION') {
            return BG_BLUE;
        } else if (category === 'CLEARANCE') {
            return BG_GREEN;
        } else {
            return '#f2f2f2';
        }
    }};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2;
  h6 {
    font-size: 16px;
    font-weight: 700;
  }
  div {
    font-size: 14px;
    line-height: 24px;
  }
`;

const NoHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: #f2f2f2;
  p {
    font-size: 12px;
    text-align: center;
  }
`;


const HistoryButtonWrapper = styled(MoreBtnWrapper)`
  padding-bottom: 16px;
`;