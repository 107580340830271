import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { ICON } from '@ts-types/common';
import { CDN } from '@service/constants/urls';

interface IconProps {
  name: ICON;
  width?: number;
  height?: number;
  onClick?: (e: React.MouseEventHandler<HTMLImageElement>) => void | null;
}

const getIconSize = (name: ICON) => {
  const sizeEnum = name.split('-')[1];
  switch (sizeEnum) {
    case 'small':
      return 20;
    case 'regular':
      return 24;
    case 'large':
      return 28;
    default:
      return 24;
  }
};

const Icon = (props: IconProps) => {
  const { name, onClick = null, width, height, ...otherProps } = props;

  const size = getIconSize(name);

  const onClickIcon = (e: React.MouseEventHandler<HTMLImageElement>) => {
    if (onClick === null) {
      return;
    }
    onClick(e);
  };

  return (
    <IconImage
      name={name}
      size={size}
      width={width}
      height={height}
      {...otherProps}
      onClick={onClickIcon}
    />
  );
};

export default Icon;

interface IconImageAttributes {
  name: ICON;
  size: 20 | 24 | 28;
}

const IconImage = styled(Image).attrs<IconImageAttributes>((props) => ({
  src: `${CDN}icons/${props.name}.png`,
  alt: `${props.name} 아이콘`,
  width: props.width ?? props.size,
  height: props.height ?? props.size,
}))`
  cursor: pointer;
`;
