import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FlexStyle, MobileSection } from '@styles/carFaxResultStyle';
import { ContentProps } from '@components/carfax/CarDetailContent';
import Modal from '@components/common/Modal';
import { ErrorWrapper, Message } from '@pages/result';
import { NotoBold, NotoRegular } from '@styles/global';


// transactionId: string;
// carNo: string;
// ownerName: string;
// isMobile?: boolean;
interface BriefInfoProps {
  carNo: string;
  isMobile?: boolean;
  specData?: any;
  specError?: any;
}

const BriefInfo = ({
  carNo,
  isMobile,
  specData,
  specError,
}: BriefInfoProps) => {
  const modalRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      {[40040].includes(specError?.error?.code) && (
        <MobileSection>
          <p className='error'>본 차량은 상품용 차량이 아닙니다.</p>
          <p className='error' style={{ marginBottom: '20px' }}>
            정보 열람이 제한됩니다.
          </p>
        </MobileSection>
      )}

      {!isMobile && (
        <InfoWrapper>
          <Flex>
            <div>
              {/* 차량이름 */}
              <Flex style={{ marginBottom: 10, justifyContent: 'flex-start' }}>
                <h3>
                  {specData?.carSpec?.brandName}{' '}{specData?.carSpec?.modelName}
                </h3>
              </Flex>
              <p className='subTitle'>{specData?.carSpec?.gradeName}{' '}{specData?.carSpec?.subGradeName}</p>
            </div>
          </Flex>
        </InfoWrapper >
      )}
      {
        isMobile && (
          <MobileWrapper>
            <TitleBg>
              <Flex style={{ justifyContent: 'flex-start' }}>
                <h3>
                  {specData?.carSpec?.brandName}{' '}{specData?.carSpec?.modelName}
                </h3>
              </Flex>
              <Flex style={{ justifyContent: 'space-between' }}>
                <p className='subTitle'>{specData?.carSpec?.gradeName}{' '}{specData?.carSpec?.subGradeName}</p>
                <p className='subTitle'>{carNo}</p>
              </Flex>
            </TitleBg>
          </MobileWrapper>
        )
      }

      <Modal ref={modalRef} leftButtonTitle='확인'>
        <ErrorWrapper>
          <Message>
            일시적인 오류로 서버와 연결이 끊겼습니다. <br />
            오류가 계속되면 고객센터로 문의해 주세요. <br />
            (고객센터 : 1800-0456)
          </Message>
        </ErrorWrapper>
      </Modal>
    </Container >
  );
};

export default BriefInfo;

const Container = styled.div`
  .error {
    ${NotoRegular}
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.primary};
  }
`;

const InfoWrapper = styled.div`
  height: fit-content;
  width: 100%;
  padding-bottom: 30px;
`;

const Flex = styled.div`
  ${FlexStyle}
  justify-content: space-between;

  h3 {
    ${NotoBold}
    font-size: 28px;
    letter-spacing: -2%;
    color: #121212;
  }
`;

const MobileWrapper = styled.div``;

const TitleBg = styled.div`
  width: 100%;
  height: fit-content;
  padding: 30px 20px;
  position: relative;
  border-top: 8px solid #eaeaea;
  background-color: #f2f2f2;
  margin-bottom: 32px;

  .subTitle {
    ${NotoRegular}
    font-size:14px;
    color: #555555;
  }

  h3 {
    ${NotoBold}
    font-size: 20px;
    letter-spacing: -2%;
    color: #121212;
  }

  .car-image {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
  }
`;
