import {
  DEFAULT_REQUEST,
  GRADES_RESPONSE,
  INSURANCE_RESPONSE,
  OPTION_REQUEST,
  OPTION_RESPONSE,
  RECALL_RESPONSE,
  REG_RESPONSE,
  RES_INFORMATION,
  SEARCH_ALL_RESPONSE,
} from '@ts-types/search';
import { RESPONSE } from '@ts-types/common';
import ApiClient from './ApiClient';
import {
  INSURANCE,
  OPTION,
  RECALL,
  CARFAX,
  SPEC,
  HIDDEN,
  GRADES,
  REGISTRATION,
  DELETE_ALL,
  DELETE_ONE,
  INFORMATION,
  INFO_OPTIONS,
  SELECTED_OPTIONS,
} from './ApiPath';
import { isEmpty } from '@service/utils/validation';

// 사용불가 api
export const postCarInformation = async ({ carNo }): Promise<RES_INFORMATION> => {
  try {
    const res = await ApiClient.post(`${CARFAX}/${INFORMATION}`, { carNo });
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// 2024.5 업데이트
export const postCarSpec = async ({
  carNo,
  ownerName,
  transactionId,
  token,
}: DEFAULT_REQUEST): Promise<SEARCH_ALL_RESPONSE> => {
  try {
    const res = await ApiClient.post<SEARCH_ALL_RESPONSE>(
      `${CARFAX}/${SPEC}`,
      { carNo, ownerName, transactionId },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

// POST /api/carfax/record-summary 
export const postRecordSummary = async ({
  carNo,
  ownerName,
  transactionId,
  token,
}: DEFAULT_REQUEST): Promise<SEARCH_ALL_RESPONSE> => {
  try {
    const res = await ApiClient.post<SEARCH_ALL_RESPONSE>(
      `${CARFAX}/${HIDDEN}`,
      { carNo, ownerName, transactionId },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export const postRegister = async ({
  carNo,
  ownerName,
  transactionId,
  token,
}: DEFAULT_REQUEST): Promise<REG_RESPONSE> => {
  try {
    const res = await ApiClient.post<REG_RESPONSE>(
      `${CARFAX}/${REGISTRATION}`,
      {
        carNo,
        ownerName,
        transactionId,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// record-summary로 통합
export const postRecallInfo = async ({
  carNo,
  token,
  ownerName,
  transactionId,
}: DEFAULT_REQUEST): Promise<RECALL_RESPONSE> => {
  try {
    const res = await ApiClient.post<RECALL_RESPONSE>(
      `${CARFAX}/${RECALL}`,
      {
        carNo,
        ownerName,
        transactionId,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postCarGrades = async ({
  carNo,
  token,
  ownerName,
  transactionId,
}: DEFAULT_REQUEST): Promise<GRADES_RESPONSE> => {
  try {
    const res = await ApiClient.post<GRADES_RESPONSE>(
      `${CARFAX}/${GRADES}`,
      {
        carNo,
        ownerName,
        transactionId,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );
    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postInfoOptions = async ({ firstRegistrationDate, gradeId }) => {
  try {
    const res = await ApiClient.post(`${CARFAX}/${INFO_OPTIONS}`, {
      firstRegistrationDate,
      gradeId,
    });

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postGetchaOptions = async ({ carNo }) => {
  if (isEmpty(carNo)) return;
  try {
    const res = await ApiClient.post(`${CARFAX}/${SELECTED_OPTIONS}`, {
      carNo,
    });

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postCarOption = async ({
  carNo,
  token,
  ownerName,
  transactionId,
  gradeId,
}: OPTION_REQUEST): Promise<OPTION_RESPONSE> => {
  try {
    const res = await ApiClient.post<OPTION_RESPONSE>(
      `${CARFAX}/${OPTION}`,
      {
        carNo,
        ownerName,
        transactionId,
        gradeId,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// record-summary로 통합
export const postInsuranceInfo = async ({
  carNo,
  ownerName,
  token,
  transactionId,
}: DEFAULT_REQUEST): Promise<INSURANCE_RESPONSE> => {
  try {
    const res = await ApiClient.post<INSURANCE_RESPONSE>(
      `${CARFAX}/${INSURANCE}`,
      {
        carNo,
        ownerName,
        transactionId,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAllHistory = async ({ token }): Promise<RESPONSE> => {
  // if (isEmpty(token)) return;
  try {
    const res = await ApiClient.delete<RESPONSE>(`${CARFAX}/${DELETE_ALL}`, {
      headers: {
        'x-cf-access-token': token,
      },
    });

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteOneHistory = async ({
  token,
  ownerName,
  carNo,
}: DEFAULT_REQUEST): Promise<RESPONSE> => {
  try {
    const res = await ApiClient.post<RESPONSE>(
      `${CARFAX}/${DELETE_ONE}`,
      { carNo, ownerName },
      {
        headers: {
          'x-cf-access-token': token,
        },
      },
    );

    return res?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
