import useUserStore from '@store/userStore';
import {
  CHECK_CODE_REQUEST,
  LOGIN_REQUEST,
  SEND_CODE_REQUEST,
  SEND_CODE_RESPONSE,
  SIGN_UP_REQUEST,
  SIGN_UP_RESPONSE,
  TERMS_RESPONSE,
  TRANSACTION_REQUEST,
  USER_INFO_REQUEST,
} from '@ts-types/auth';
import ApiClient from './ApiClient';
import {
  CARFAX,
  CHECK_CODE,
  LOGIN,
  SEND_CODE,
  SIGN_UP,
  TERMS,
  TRANSACTION,
  USER_INFO,
} from './ApiPath';

/*
  인증코드를 받기 위한 호출
*/
export const postPhoneCode = async ({
  phone,
}: SEND_CODE_REQUEST): Promise<SEND_CODE_RESPONSE> => {
  try {
    const res = await ApiClient.post<SEND_CODE_RESPONSE>(
      `${CARFAX}/${SEND_CODE}`,
      { phone }
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/*
  인증코드 검증 호출
*/
export const postValidateCode = async ({
  phone,
  authCode,
}: CHECK_CODE_REQUEST): Promise<SEND_CODE_RESPONSE> => {
  try {
    const res = await ApiClient.post<SEND_CODE_RESPONSE>(
      `${CARFAX}/${CHECK_CODE}`,
      { phone, authCode }
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const postSignUp = async ({
  phone,
  password,
  authCode,
  name,
  isMarketingAgree,
}: SIGN_UP_REQUEST): Promise<SIGN_UP_RESPONSE> => {
  try {
    const res = await ApiClient.post<SIGN_UP_RESPONSE>(`${CARFAX}/${SIGN_UP}`, {
      phone,
      password,
      authCode,
      name,
      isMarketingAgree,
    });
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const postLogin = async ({
  phone,
  password,
}: LOGIN_REQUEST): Promise<SIGN_UP_RESPONSE> => {
  try {
    const res = await ApiClient.post<SIGN_UP_RESPONSE>(`${CARFAX}/${LOGIN}`, {
      phone,
      password,
    });
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/*
  유저의 정보 조회(원부조회 잔여횟수)
*/
export const getUserInfo = async ({
  token,
}: TRANSACTION_REQUEST): Promise<SIGN_UP_RESPONSE> => {
  try {
    const res = await ApiClient.get<SIGN_UP_RESPONSE>(
      `${CARFAX}/${USER_INFO}`,
      {
        headers: {
          'x-cf-access-token': token,
        },
      }
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/*
  transaction ID 받기 : 호출횟수 = 차감횟수
*/
export const postTransaction = async ({
  token,
  carNo,
  ownerName,
}: USER_INFO_REQUEST): Promise<SEND_CODE_RESPONSE> => {
  try {
    const res = await ApiClient.post<SEND_CODE_RESPONSE>(
      `${CARFAX}/${TRANSACTION}`,
      {
        carNo,
        ownerName,
      },
      {
        headers: {
          'x-cf-access-token': token,
        },
      }
    );
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/*
  약관 버전과 파일 받아오기 : 개인정보처리방침 / 이용약관 / 개인정보 수집동의
*/
export const getTerms = async (): Promise<TERMS_RESPONSE> => {
  try {
    const res = await ApiClient.get<TERMS_RESPONSE>(`${CARFAX}/${TERMS}`);
    return res?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
