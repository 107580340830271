import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import LoadingCircle from '@public/lottie/loading/loading-circle';
import Lottie from 'lottie-web';

interface LoadingProps {
  width?: number;
}

const Loading = ({ width = 0 }: LoadingProps) => {
  const lottieRef = useRef(null);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: lottieRef.current,
      renderer: 'svg',
      autoplay: true,
      animationData: LoadingCircle,
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <Container>
      <LottieContainer ref={lottieRef} width={width} />
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const LottieContainer = styled.div`
  width: ${({ width }) => (width ? width : 64)}px;
  height: ${({ width }) => (width ? width : 64)}px;
`;
