import styled from 'styled-components';
import { addComma, convertNumToKrw } from '@service/utils/number';
import {
  BlackBorderLine,
  BorderLine,
  Empty,
  FlexStyle,
  GreyBox,
  MaxWidth,
  Section,
  SectionTitle,
} from '@styles/carFaxResultStyle';
import { NotoBold, NotoMedium, NotoRegular } from '@styles/global';
import { PriceOptionProps } from './MobilePriceOption';
import { replaceRomanNumberUnicodeToAlphabet } from '@service/utils/string';
import Skeleton from '@components/common/Skeleton';
import { useState } from 'react';

const PriceOption = ({ specData, isSpecLoading }: PriceOptionProps) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const numberOfRows = Math.ceil(specData?.carAddOptions?.length / 3);
  const emptyCells = numberOfRows * 3 - specData?.carAddOptions?.length;

  const guessSelectedOptionPriceList = (
    //selectedOptionName: string,
    optionList: Array<any>,
    selectedOptionList: Array<any>,
  ) => {
    if (!optionList || optionList?.length <= 0) {
      if (selectedOptionList && selectedOptionList?.length > 0) {
        return selectedOptionList?.map((item) => {
          return {
            optName: item,
            optPrice: -1,
          };
        });
      } else return [];
    }
    if (!selectedOptionList || selectedOptionList.length <= 0) return [];



    /* 제조사에서 가저온 옵션중 공백 및 특정 문구는 삭제하고 비교 */
    /* 옵션명에 로마숫자가 포함되어 있을 경우 로마숫자를 알파벳으로 변경하여 비교 */
    let trimmedSelectedOptionList = selectedOptionList?.map((item: string) => {
      return replaceRomanNumberUnicodeToAlphabet(
        item?.replace(/ /g, '')
          .replace(/\(울산희망시\)/g, '')
          .replace(/드라이빙어시스턴트/, '드라이빙어시스턴스')
          .replace(/썬루프/, '선루프')
          .replace(/스마트센스III/, '스마트센스3')
          .replace(/스마트센스Ⅲ/, '스마트센스3')
          .replace(/스마트센스II/, '스마트센스2')
          .replace(/스마트센스Ⅱ/, '스마트센스2')
          .replace(/스마트센스I/, '스마트센스1')
          .replace(/스마트센스Ⅰ/, '스마트센스1')
          .replace(/인포테인먼트내비IV/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비Ⅳ/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비III/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비Ⅲ/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비II/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비Ⅱ/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비I/, '인포테인먼트내비1')
          .replace(/인포테인먼트내비Ⅰ/, '인포테인먼트내비1')
          .replace(/셀렉션IV/, '셀렉션4')
          .replace(/셀렉션Ⅳ/, '셀렉션4')
          .replace(/셀렉션III/, '셀렉션3')
          .replace(/셀렉션Ⅲ/, '셀렉션3')
          .replace(/셀렉션II/, '셀렉션2')
          .replace(/셀렉션Ⅱ/, '셀렉션2')
          .replace(/셀렉션I/, '셀렉션1')
          .replace(/셀렉션Ⅰ/, '셀렉션1')
          .replace(/패키지IV/, '패키지4')
          .replace(/패키지Ⅳ/, '패키지4')
          .replace(/패키지III/, '패키지3')
          .replace(/패키지Ⅲ/, '패키지3')
          .replace(/패키지II/, '패키지2')
          .replace(/패키지Ⅱ/, '패키지2')
          .replace(/패키지I/, '패키지1')
          .replace(/패키지Ⅰ/, '패키지1')
      );
    });

    trimmedSelectedOptionList = trimmedSelectedOptionList.map(item => item.split('+')).flat();

    /* 비교할 전체 선택 옵션 리스트 */
    let trimmedOptionList = optionList?.map((item, index) => {
      return replaceRomanNumberUnicodeToAlphabet(
        item?.optName?.replace(/ /g, '')
          .replace(/내비게이션선택시/, '')
          .replace(/스마트센스III/, '스마트센스3')
          .replace(/스마트센스Ⅲ/, '스마트센스3')
          .replace(/스마트센스II/, '스마트센스2')
          .replace(/스마트센스Ⅱ/, '스마트센스2')
          .replace(/스마트센스I/, '스마트센스1')
          .replace(/스마트센스Ⅰ/, '스마트센스1')
          .replace(/인포테인먼트내비IV/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비Ⅳ/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비III/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비Ⅲ/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비II/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비Ⅱ/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비I/, '인포테인먼트내비1')
          .replace(/인포테인먼트내비Ⅰ/, '인포테인먼트내비1')
          .replace(/셀렉션IV/, '셀렉션4')
          .replace(/셀렉션Ⅳ/, '셀렉션4')
          .replace(/셀렉션III/, '셀렉션3')
          .replace(/셀렉션Ⅲ/, '셀렉션3')
          .replace(/셀렉션II/, '셀렉션2')
          .replace(/셀렉션Ⅱ/, '셀렉션2')
          .replace(/셀렉션I/, '셀렉션1')
          .replace(/셀렉션Ⅰ/, '셀렉션1')
          .replace(/패키지IV/, '패키지4')
          .replace(/패키지Ⅳ/, '패키지4')
          .replace(/패키지III/, '패키지3')
          .replace(/패키지Ⅲ/, '패키지3')
          .replace(/패키지II/, '패키지2')
          .replace(/패키지Ⅱ/, '패키지2')
          .replace(/패키지I/, '패키지1')
          .replace(/패키지Ⅰ/, '패키지1')
          .replace(/미쉐린타이어/, '인치')
          .replace(/콘티넨탈타이어/, '인치')
          .replace(/피렐리타이어/, '인치')
      );
    });

    let guessedSelectedOptionsFromOptionList =
      trimmedOptionList?.map((item, index) => {
        const subItemLength = item.split('+').length;
        console.log(item.split('+'));
        const filteredItemList = item.split('+').map((subItem, subIndex) => {
          const selectedItem = trimmedSelectedOptionList?.filter((selectedItem) => subItem.includes(selectedItem) || selectedItem.includes(subItem));
          console.log('selected item : ' + selectedItem)
          return selectedItem[0] ?? null;
        });

        const filteredList = filteredItemList?.filter((item) => item);

        console.log('filtered list : ', filteredList);

        if (filteredList && filteredList.length === subItemLength) {
          console.log('same : ', item.split('+'), optionList[index]);
          return optionList[index];
        }
      });

    return guessedSelectedOptionsFromOptionList?.filter((option, _, array) =>
      !array.some(otherOption =>
        otherOption?.optName !== option?.optName && otherOption?.optName?.includes(option?.optName)
      )
    ).filter((item) => item);
  };

  // const guessedOptionPriceList = guessSelectedOptionPriceList(selectedOptions, optData?.selectedOptions?.options);

  // const numberOfRows = Math.ceil(guessedOptionPriceList?.length / 3);
  // const emptyCells = numberOfRows * 3 - guessedOptionPriceList?.length;

  // const numberOfRows = Math.ceil(optData?.selectedOptions?.options?.length / 3);
  // const emptyCells = numberOfRows * 3 - optData?.selectedOptions?.options?.length;


  const guessOptionPrice = (
    selectedOptionName: string,
    optionList: Array<any>,
  ) => {
    /* 제조사에서 가저온 옵션중 공백 및 특정 문구는 삭제하고 비교 */
    /* 옵션명에 로마숫자가 포함되어 있을 경우 로마숫자를 알파벳으로 변경하여 비교 */
    const trimmedOptionName = replaceRomanNumberUnicodeToAlphabet(
      selectedOptionName.replace(/ /g, '').replace(/\(울산희망시\)/g, ''),
    );
    if (optionList?.length > 0) {
      const option = optionList?.find((el) =>
        el?.optName.replace(/ /g, '').includes(trimmedOptionName),
      );
      if (!option) return -1;
      return option?.optPrice;
    }
    return 0;
  };

  if (isSpecLoading)
    return (
      <Section>
        <SectionTitle>가격 정보</SectionTitle>
        <Skeleton width='100%' height={598} />
      </Section>
    );

  return (
    <Section>
      <SectionTitle>가격 정보</SectionTitle>
      <BlackBorderLine />

      <GridByThree>
        <div className='price'>
          <p>
            출고가 <span className='grey'>(VAT,선택옵션포함)</span>
          </p>
          <h3
            className={`red price-value ${String(specData?.carSpec?.salePrice).startsWith('1') ? 'ml' : ''
              }`}
          >
            {+specData?.carSpec?.salePrice > 0 ? convertNumToKrw(+specData?.carSpec?.salePrice) : '-'}
            <span className='unit'>만원</span>
          </h3>
        </div>

        <div className='price'>
          <p>기본가</p>
          {/* 조합 요청으로, 정확도 높이기 전까지 임시 주석 처리 */}
          {/* <h3
            className={`price-value ${
              String(defaultPrice).startsWith('1') ? 'ml' : ''
            }`}
          >
            {defaultPrice > 0 ? convertNumToKrw(defaultPrice) : '-'}
            <span className='unit'>만원</span>
          </h3> */}
          {/* 출고가 없을 경우 임시로 기본가 노출 */}
          <h3>
            {/* {+carPrice <= 0 && defaultPrice > 0 ? convertNumToKrw(defaultPrice) : '-'} */}
            -
            <span className='unit'>만원</span>
          </h3>
        </div>
        <div className='price'>
          <p>출고시 옵션가</p>
          {/* 조합 요청으로, 정확도 높이기 전까지 임시 주석 처리 */}
          {/* <h3
            className={`price-value ${
              String(+carPrice - defaultPrice).startsWith('1') ? 'ml' : ''
            }`}
          >
            {+carPrice > 0 && defaultPrice > 0 && +carPrice > defaultPrice
              ? convertNumToKrw(+carPrice - defaultPrice)
              : '-'}
            <span className='unit'>만원</span>
          </h3> */}
          <h3
            className={`price-value`}
          >
            -
            <span className='unit'>만원</span>
          </h3>
        </div>
      </GridByThree>
      <BorderLine />

      <SmallGreyBox>출고시 옵션</SmallGreyBox>
      {specData?.carAddOptions?.length > 0 ? (
        <GridByThree>
          {specData?.carAddOptions?.map((el) => {
            return (
              <div className='selected-option' key={el}>
                <p>{el.optionName}</p>
                <div className='option-price-value'>
                  {el.optionPrice > 0
                    ? String(el.optionPrice)?.includes(',')
                      ? el.optionPrice
                      : addComma(el.optionPrice) + ' 원'
                    : '-'}
                </div>
              </div>
            );
          })}
          {/* 기존 선택 품목 리스트에서 출고시 옵션 가격 추정하는 로직 주석 처리 */}
          {/* {guessedOptionPriceList?.map((el) => {
            return (
              <div className='selected-option' key={el}>
                <p>{el.optName}</p>
                <div className='option-price-value'>
                  {el.optPrice > 0
                    ? String(el.optPrice)?.includes(',')
                      ? el.optPrice
                      : addComma(el.optPrice) + ' 원'
                    : '-'}
                </div>
              </div>
            );
          })} */}
          {[...Array(emptyCells)]?.map((e, i) => {
            return <div key={`empty-${i}`} />;
          })}
        </GridByThree>
      ) : (
        <Empty>조회된 출고시 옵션이 없습니다.</Empty>
      )}
      <BorderLine />

      <GridByTwo>
        <SmallGreyBox>기본품목</SmallGreyBox>
        <SmallGreyBox>선택품목</SmallGreyBox>
      </GridByTwo>
      <GridByTwo>
        {specData?.carOptions?.length > 0 ? (
          <div className='basic-options'>
            <GridByFour>
              {specData?.carOptions?.map((opt, i) => {
                return (
                  <BasicOption key={`${opt}-${i}`}>
                    <h5>{opt.parts}</h5>
                    <p>{opt.optionName}</p>
                  </BasicOption>
                );
              })}
            </GridByFour>
            {/* <Space h={20} />
            <MoreBtnWrapper>
              <MoreBtn onClick={() => setShowAll((prev) => !prev)}>
                <h5>{showAll ? '옵션 간략히 보기' : '옵션 전체보기'}</h5>
              </MoreBtn>
            </MoreBtnWrapper> */}
          </div>
        ) : (
          <div className='selected'>
            <Empty>조회된 기본 품목이 없습니다.</Empty>
          </div>
        )}
        <div className='selected'>
          {specData?.carAddOptions?.length === 0 ? (
            <Empty>조회된 유료 옵션이 없습니다.</Empty>
          ) : (
            <>
              {specData?.carAddOptions?.map((opt) => {
                return (
                  <ChargedOption key={`${opt.optionName}-${opt?.optionPrice}`}>
                    <p>- {opt.optionName}</p>
                    <h5>
                      {String(opt?.optionPrice)?.includes(',')
                        ? opt?.optionPrice
                        : addComma(opt?.optionPrice)}{' '}
                      원
                    </h5>
                  </ChargedOption>
                );
              })}
            </>
          )}
        </div>
      </GridByTwo>
      <BlackBorderLine />
    </Section>
  );
};

export default PriceOption;

const GridByThree = styled.div`
  width: ${MaxWidth}px;
  background: #d5d8de;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1px;
  row-gap: 1px;

  .price {
    p {
      ${NotoRegular}
      font-size:12px;
      margin-bottom: 12px;
    }

    .unit {
      ${NotoRegular}
      font-size: 16px;
      font-weight: 100;
      color: #121212;
      margin-left: 2px;
    }

    .grey {
      ${NotoRegular}
      color: #aeaeae;
      font-size: 11px;
    }
  }

  div {
    background-color: #ffffff;
    width: 100%;
    height: 97px;
    padding: 24px 16px;

    p {
      ${NotoRegular}
      font-size:12px;
      color: #181818;
    }

    h3 {
      font-size: 20px;
    }

    .red {
      color: ${({ theme }) => theme.primary};
    }

    .price-value {
      font-family: DSEG7ClassicMini-Bold;
      letter-spacing: -2px;
    }

    .ml {
      margin-left: -10px; // 폰트스타일때문에 left에 영역생김
    }

    .selected-option {
      display: flex;
      height: 40px;
      padding: 0px;
      flex-direction: column;
    }

    .option-price-value {
      height: 40px;
      padding: 0px;
      display: flex;
      font-size: 14px;
      ${NotoBold}
      align-items: flex-end;
      justify-content: flex-end;
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const GridByTwo = styled.div`
  width: ${MaxWidth}px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1px;
  background-color: #d5d8de;

  .selected {
    background-color: #ffffff;
    padding: 20px 16px;
  }

  .basic-options {
    background-color: #ffffff;
    width: 100%;
    padding: 30px 0;
  }
`;

const GridByFour = styled.div`
  width: ${MaxWidth / 2}px;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 12px;

  div {
    ${FlexStyle}
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    padding: 15px;

    p {
      margin-top: 8px;
      ${NotoRegular}
      font-size:12px;
      text-align: center;
      line-height: 20px;
      color: #555555;
    }
  }
`;

const BasicOption = styled.div`
  width: 100%;
  ${FlexStyle};
  justify-content: space-between;

  p {
    ${NotoRegular}
    font-size:12px;
    line-height: 240%;
    color: #555555;
  }

  h5 {
    ${NotoBold}
    color: #555555;
  }

  p:nth-child(1) {
    text-align: left;
    max-width: calc(100% - 100px);
  }
`;

const ChargedOption = styled.div`
  width: 100%;
  ${FlexStyle};
  justify-content: space-between;

  p {
    ${NotoRegular}
    font-size:12px;
    line-height: 240%;
    color: #555555;
  }

  h5 {
    ${NotoBold}
    color: #555555;
  }

  p:nth-child(1) {
    text-align: left;
    max-width: calc(100% - 100px);
  }
`;

const SmallGreyBox = styled(GreyBox)`
  height: 36px;
`;

const RestOption = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div {
    margin: 6px;
  }

  p {
    ${NotoRegular}
    font-size:12px;
    line-height: 24px;
    color: #555555;
    margin-bottom: 6px;
  }

  .title {
    color: #555555;
    ${NotoMedium}
    font-size:14px;
    margin-bottom: 12px;
  }
`;
