import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import copy from 'copy-to-clipboard';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { useGetTransaction } from '@service/query/useSearch';
import CarDetailContent from '@components/carfax/CarDetailContent';
import { isEmpty } from '@service/utils/validation';
import Modal from '@components/common/Modal';
import { B18, R11, R14 } from '@styles/global';
import useUserStore from '@store/userStore';
import { getUserInfo } from '@api/Auth';
import useSearchStore from '@store/searchStore';
import { SIGN_UP_RESPONSE } from '@ts-types/auth';
import { isMobile } from '@service/utils/isMobile';
import GAHead from '@components/carfax/GAHead';
import { useIsMobile } from '@service/hooks/useIsMobile';
import TestCarNumberInput from '@components/carfax/TestCarInput';
import { FlexStyle } from '@styles/carFaxResultStyle';
import TopBar from '@components/common/TopBar';
import Toast from '@components/common/Toast';

const CarCheckResult = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const carNo: string = router.query?.no;
  const ownerName: string = router.query?.name;
  const modalRef = useRef();
  const loginModalRef = useRef();
  const toastRef = useRef<HTMLInputElement>(null);

  const isMobile = useIsMobile();

  const { transactionList, selectedCar, setValue } = useSearchStore();
  const { token, saveUser, reset, searchHistory } = useUserStore();

  const {
    mutate: mutateUserInfo,
    error: userInfoError,
    isError: isUserInfoError,
  } = useMutation<SIGN_UP_RESPONSE, AxiosError>(() => getUserInfo({ token }), {
    onSuccess: (res) => {
      const userData = res?.result;
      // console.log('결과에서 유저', userData);
      if (!isEmpty(userData)) saveUser(userData);
    },
    onError: (err) => {
      reset();
      router.push('/login');
    },
  });

  /** 서비스 점검중 isHold = true */
  const isHold = false;

  const { isError: isTransactionError, error: transactionError } =
    useGetTransaction({
      carNo,
      token,
      ownerName,
      isHold,
      successFn: () => {
        mutateUserInfo();
      },
      settleFn: (res) => {
        // 차량번호에 부여받은 transactionId를 특정짓지 못하고 다른 차량번호에도 동일하게 들어가는 경우가 있어서 매칭하는 구조로 저장함
        setValue('transactionList', {
          ...transactionList,
          [`${carNo}-${ownerName}`]: res,
        });
      },
    });

  const targetTransactionId = transactionList[`${carNo}-${ownerName}`];

  const goMain = () => {
    router.push('/');
  };

  useEffect(() => {
    if (isEmpty(token)) {
      // 로그인 회원가입 유도 팝업 필요
      loginModalRef.current?.open();
      return;
    }

    if (isTransactionError) {
      modalRef.current?.open();
    }

    if (!isEmpty(token) && !isTransactionError && !transactionError) {
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }
  }, [token, isTransactionError, transactionError]);

  useEffect(() => {
    if (!isEmpty(targetTransactionId)) {
      window.dataLayer = window?.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('event', 'carfax_search_complete', {
        event_label: '카팩스 차량조회 완료',
      });
    }
  }, [targetTransactionId]);

  return (
    <>
      <GAHead />
      <Container>
        {isMobile ? (
          <MobileInput>
            <TopBar
              title='상세 사양조회'
              hasBorder
              bgColor='#40CCFF'
              color='#ffffff'
              leftIconPressed={() => router.back()}
              // /* 공유하기 버튼 임시 삭제 */
              // rightFirstIcon='pictogram-regular-share_header'
              // rightFirstPressed={() => {
              //   copy(window.location.href);
              //   toastRef?.current?.open();
              // }}
            />
            <TestCarNumberInput isMobile={isMobile} />
          </MobileInput>
        ) : (
          <InputBg>
            {/* <CarNumberInput /> */}
            <TestCarNumberInput isMobile={isMobile} />
          </InputBg>
        )}

        {isHold && (
          <ServiceAlert>
            <p>서비스 점검 중입니다.</p>
          </ServiceAlert>
        )}

        <div ref={scrollRef}>
          {!isHold && !isEmpty(token) && (
            <CarDetailContent transactionId={targetTransactionId} />
          )}
        </div>
      </Container>

      <Toast message='페이지 주소가 복사되었습니다.' ref={toastRef} />

      <Modal
        ref={modalRef}
        leftButtonTitle='확인'
        leftButtonPressed={() => {
          router.replace('/');
        }}
      >
        <ErrorWrapper>
          <Message>
            {transactionError?.status !== 500 &&
            !isEmpty(transactionError?.error?.message) ? (
              transactionError.error.message
            ) : (
              <>
                일시적인 오류로 서버와 연결이 끊겼습니다. <br />
                오류가 계속되면 고객센터로 문의해 주세요. <br />
                (고객센터 : 1800-0456)
              </>
            )}
          </Message>
        </ErrorWrapper>
      </Modal>

      <Modal
        ref={loginModalRef}
        leftButtonTitle='취소'
        rightButtonTitle='확인'
        leftButtonPressed={() => router.replace('/')}
        rightButtonPressed={() => router.replace('/login')}
      >
        <ErrorWrapper>
          <Message>
            로그인 후 이용 가능합니다. <br />
            로그인 페이지로 이동하시겠습니까?
          </Message>
        </ErrorWrapper>
      </Modal>
    </>
  );
};

export default CarCheckResult;

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputBg = styled.div`
  height: 156px;
  background-color: #555555;
  width: 100%;
  ${FlexStyle}
  justify-content: center;
`;

const MobileInput = styled.div`
  padding-top: 56px;
  padding-bottom: 40px;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  max-width: 372px;
  padding: 40px 46px 30px 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h5 {
    ${B18}
    color:${({ theme }) => theme.base.black2};
    margin-bottom: 18px;
  }
`;

export const Message = styled.div`
  text-align: center;
  max-width: 280px;
  ${R14}
  line-height:140%;
  color: ${({ theme }) => theme.base.black2};
`;

const Extra = styled.div`
  margin-top: 18px;
  p {
    text-align: center;
    ${R11}
    line-height:140%;
    color: ${({ theme }) => theme.base.grey400};
  }
`;

const ServiceAlert = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${({ isMobile }) =>
    isMobile ? '30px 20px 60px 20px' : '40px 40px 60px 40px'};
  margin-bottom: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: ${({ theme }) => theme.base.white};
`;
