import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  SpecProps,
  getFirstRegDate,
} from '@components/carfax/CarDetailContent';
import { addComma } from '@service/utils/number';
import { isEmpty } from '@service/utils/validation';
import {
  FlexStyle,
  Section,
} from '@styles/carFaxResultStyle';
import Skeleton from '@components/common/Skeleton';
import { NotoRegular } from '@styles/global';
import { getWindowDimension } from '@service/utils/getWindowDimestion';

const MobileSpec = ({ specData, isSpecRefetching, odometer }: SpecProps) => {
  const { width } = getWindowDimension();

  const [shape, setShape] = useState<string>('');
  const isLoading = isSpecRefetching;

  useEffect(() => {
    if (specData?.carOptions?.length > 0) {
      specData?.carOptions.forEach((option) => {
        if (option.parts === '바디') {
          setShape(option.optionName);
        }
      });
    }
  }, [specData]);

  if (isLoading)
    return (
      <Section>
        <Skeleton width='100%' height={166} />
      </Section>
    );

  return (
    <Section style={{ margin: 0 }}>
      <SpecGrid w={width}>
        <div>
          <Label>형식연도</Label>
          <Value>
            {isEmpty(specData?.carSpec?.saleYear)
              ? '-'
              : specData?.carSpec?.saleYear}
          </Value>
        </div>
        <div>
          <Label>최초 등록일</Label>
          <Value>
            {!isEmpty(specData?.carSpec?.carRegistrationDate) ? (
              <>
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.yyyy}
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.mm
                  ? `.${getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.mm}`
                  : ''}
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.dd
                  ? `.${getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.dd}`
                  : ''}
              </>
            ) : (
              '-'
            )}
          </Value>
        </div>

        <div>
          <Label>색상</Label>
          <Value>
            {!isEmpty(specData?.carSpec?.color)
              ? specData?.carSpec?.color
              : '-'}
          </Value>
        </div>
        <div>
          <Label>주행거리</Label>
          <Value>
          {odometer === 0 ? '-' : `${addComma(Number(odometer))}km`}
            {/* {!isEmpty(insuranceData?.odometerHistory[0]?.distance)
              ? `${addComma(insuranceData?.odometerHistory[0]?.distance)}km`
              : '-'} */}
          </Value>
        </div>

        <div>
          <Label>연료</Label>
          <Value>
            {isEmpty(specData?.carSpec?.fuel)
              ? '-'
              : specData?.carSpec?.fuel}
          </Value>
        </div>
        <div>
          <Label>배기량</Label>
          <Value>
            {!isEmpty(specData?.carSpec?.engineSize)
              ? `${addComma(specData?.carSpec?.engineSize)}cc`
              : '-'}
          </Value>
        </div>

        <div>
          {/* <Label>용도</Label>
          <Value>
            -
          </Value> */}
          <Label>차대번호</Label>
          <VinValue>
            {isEmpty(specData?.carSpec?.vin)
              ? '-'
              : specData?.carSpec?.vin}
          </VinValue>
        </div>
        <div>
          <Label>외형</Label>
          <Value>
            {isEmpty(shape) ? '-' : shape}
          </Value>
        </div>
      </SpecGrid>

      {/* <SpecLine>
        <Label>차대번호</Label>
        <Value>
          {isEmpty(specData?.carSpec?.vin)
            ? '-'
            : specData?.carSpec?.vin}
        </Value>
      </SpecLine> */}

      {/* <SpecLine>
        <Label>검사유효기간</Label>
        <Value>
          -
        </Value>
      </SpecLine> */}
      <Guide>
        제공되는 정보는 매매시 참고용으로만 활용하세요. <br />실 차량매입 시 정확한
        등급을 확인하시길 바랍니다.
      </Guide>
    </Section>
  );
};

export default MobileSpec;

const SpecGrid = styled.div`
  display: grid;
  max-width: ${({ w }) => w}px;
  grid-template-columns: 1fr 1fr;
  background-color: #ebebeb;
  column-gap: 1px;

  > div {
    padding-left: 32px;
    padding-bottom: 24px;
    background-color: #ffffff;
  }
`;

const Label = styled.p`
  color: #979797;
  ${NotoRegular}
  font-size: 12px;
  margin-bottom: 8px;
`;

const Value = styled.p`
  ${NotoRegular}
  font-size: 14px;
  color: #121212;
`;

const VinValue = styled.p`
  ${NotoRegular}
  font-size: 12px;
  color: #121212;
`;

const SpecLine = styled.div`
  margin-left: 40px;
  margin-bottom: 24px;
`;

const Guide = styled.div`
  background-color: #fafafa;
  padding: 14px;
  border-bottom: 8px solid #eaeaea;
  ${NotoRegular}
  font-size:11px;
  line-height: 16px;
  color: #db5b3e;
  ${FlexStyle}
  justify-content: center;
  text-align: center;
`;
