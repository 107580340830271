import { useState, useCallback, useEffect } from 'react';

const removeSpaceInString = (targetString: string) => {
  return targetString?.replace(/[" "]/g, '');
};

interface Options {
  initialValue?: string;
  maxLength?: number;
  autoFix?: boolean;
  type?: 'number' | 'string';
  regExeForValidationCheck?: RegExp;
  noSpace?: boolean;
  checkErrorOnChange?: boolean;
}

interface ReturnType {
  value: string;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isError: boolean;
  reset: () => void;
  isHasError: () => boolean;
}
/**
 * input의 값을 핸들링하는 훅입니다. validation check, auto-fix 등 기능들은 추가될 예정입니다.
 * @param {initialValue} options
 * @returns {ReturnType}
 */
const useInput = (options?: Options): ReturnType => {
  const {
    initialValue = '',
    maxLength,
    autoFix = true,
    type = 'string',
    regExeForValidationCheck,
    noSpace = false,
    checkErrorOnChange = false,
  } = options || {};
  const [value, setValue] = useState<string>(initialValue || '');
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!!initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const isHasError = () => {
    if (value.length === 0) {
      setIsError(true);
      return true;
    }
    if (regExeForValidationCheck) {
      setIsError(!regExeForValidationCheck?.test(removeSpaceInString(value)));
      return !regExeForValidationCheck?.test(removeSpaceInString(value));
    }
    return false;
  };

  useEffect(() => {
    if (checkErrorOnChange) {
      isHasError();
    }
  }, [checkErrorOnChange, value]);

  const handleNumber = useCallback(
    (receivedValue: string) => {
      let result: string = receivedValue;

      if (maxLength) {
        result = result.substring(0, maxLength);
      }

      const returnValue: string = autoFix ? result : receivedValue;

      if (noSpace) {
        return setValue(removeSpaceInString(returnValue));
      }

      setValue(returnValue);
    },
    [maxLength, autoFix, noSpace],
  );

  const handleString = useCallback(
    (receivedValue: string) => {
      let result: string = receivedValue;

      if (maxLength) {
        result = result.substring(0, maxLength);
      }

      const returnValue: string = autoFix ? result : receivedValue;

      if (noSpace) {
        return setValue(removeSpaceInString(returnValue));
      }

      setValue(returnValue);
    },
    [maxLength, autoFix, noSpace],
  );

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const targetValue: string = typeof e === 'string' ? e : e.target.value; // 직접 value 전달하는 경우 처리

      if (type === 'number') {
        handleNumber(targetValue);
        return;
      }
      handleString(targetValue);
    },
    [type, handleNumber, handleString],
  );

  const reset = () => {
    setValue('');
  };

  return { value, onChangeInput, reset, isError, isHasError };
};

export default useInput;
