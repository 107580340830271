export const decodeVinPlantsCode = (vin: string, brandName: string) => {
    if (!vin || vin?.length !== 17 || !brandName) return '';

    if (brandName === '현대' || brandName === '제네시스') {
      switch (vin[10]) {
        case 'A':
          return '아산';
        case 'C':
          return '전주';
        case 'U':
          return '울산';
        case 'W':
          return '광주';
        case '6':
          return '광명';
        default:
          return '';
      }
    } else if (brandName === '기아') {
      switch (vin[10]) {
        case 'A':
          return 'AutoLand 화성';
        case 'K':
          return 'AutoLand 광주';
        case 'S':
          return 'AutoLand 광명 (소하리)';
        case 'T':
          return '서산';
        default:
          return '';
      }
    } else if (brandName === '테슬라') {
      switch (vin[10]) {
        case 'A':
          return '미국 Texas Austin';
        case 'F':
          return '미국 California Fremont';
        case 'N':
          return '미국 Nevada Reno';
        case 'B':
          return '독일 Berlin';
        case 'C':
          return '중국 Shanghai';
        default:
          return '';
      }
    } else if (brandName?.includes('쉐보레') || brandName?.includes('GM')) {
      switch (vin[10]) {
        case 'B':
          return '부평';
        case 'C':
          return '창원';
        case 'K':
          return '군산';
        default:
          return '';
      }
    } else if (brandName?.includes('쌍용') || brandName?.includes('KG')) {
      switch (vin[10]) {
        case 'P':
          return '평택';
        default:
          return '';
      }
    } else if (
      brandName?.includes('르노') ||
      brandName === '닛산' ||
      brandName === '인피니티'
    ) {
      switch (vin[10]) {
        case 'A':
          return '영국 닛산 Sunderland';
        case 'C':
          return '미국 닛산 Smyrna';
        case 'F':
          return '멕시코 Aguascalientes';
        case 'K':
          return '멕시코 닛산 Cuernavaca';
        case 'L':
          return '멕시코 닛산 Aguascalientes';
        case 'M':
          return '일본 닛산 Tochigi';
        case 'N':
          return '미국 Canton';
        case 'P':
          return '부산 르노코리아';
        case 'K':
          return '미국 Wisconsin Kenosha';
        case 'R':
          return '프랑스 Romorantin';
        case 'W':
        case '9':
          return '일본 닛산 Kyushu';
        case 'X':
          return '일본 닛산 Shonan';
        case 'Y':
          return '멕시코 닛산 Aguascalientes';
        case '4':
          return '일본 닛산 Nagoya';
        default:
          return '';
      }
    } else if (brandName === '벤츠') {
      switch (vin[10]) {
        case 'A':
        case 'B':
        case 'C':
        case 'D':
          return '독일 Sindelfingen';
        case 'E':
          return '브라질 Minas Gerais Juiz de Fora';
        case 'F':
        case 'G':
        case 'H':
          return '독일 Bremen';
        case 'J':
        case 'K':
          return '독일 Rastatt';
        case 'L':
          return '말레이시아 Pahang Pekan';
        case 'M':
          return '영국 Woking';
        case 'N':
        case 'P':
          return '헝가리 Kecskemet';
        case 'R':
        case 'S':
          return '남아프리카공화국 East London';
        case 'T':
          return '독일 Osnabrück';
        case 'U':
          return '독일 Sindelfingen';
        case 'V':
          return '핀란드 Uusikaupunki';
        case 'W':
          return '멕시코 Aguascalientes';
        case 'X':
          return '오스트리아 Graz';
        case '1':
          return '인도 Maharashtra Pune';
        default:
          return '';
      }
    } else if (brandName === 'BMW') {
      switch (vin[10]) {
        case 'A':
        case 'F':
        case 'K':
          return '독일 Munich';
        case 'B':
        case 'C':
        case 'D':
        case 'G':
          return '독일 Dingolfing';
        case 'E':
        case 'J':
        case 'P':
          return '독일 Regensberg';
        case '9':
        case '0':
        case 'L':
        case 'M':
          return '미국 Spartanburg';
        case 'N':
          return '남아프리카공화국 Rosslyn';
        case 'W':
          return '오스트리아 Graz';
        case 'V':
          return '독일 Leipzig';
        case '4':
          return '브라질 Araquari';
        case 'M':
        case 'S':
          return '중국 Shenyang';
        default:
          return '';
      }
    } else if (brandName === '마세라티') {
      switch (vin[10]) {
        case '7':
          return '이탈리아 Cassino';
        case 'X':
          return '이탈리아 Mirafiori';
        case '0':
          return '이탈리아 Modena';
        case '1':
          return '이탈리아 Grugliasco';
        default:
          return '';
      }
    } else if (brandName === '람보르기니') {
      switch (vin[10]) {
        case 'L':
          return '이탈리아 Bologna';
        default:
          return '';
      }
    } else if (
      brandName === '랜드로버' ||
      brandName === '재규어' ||
      brandName === '레인지로버'
    ) {
      switch (vin[10]) {
        case 'A':
          return '영국 West Midlands Solihull';
        case 'H':
          return '영국 Merseyside Halewood';
        case '2':
          return '슬로바키아 Nitra';
        default:
          return '';
      }
    } else if (brandName === '볼보' || brandName === '폴스타') {
      switch (vin[10]) {
        case '0':
          return '스웨덴 Kalmar';
        case '1':
          return '스웨덴 Torslanda';
        case '2':
          return '벨기에 Ghent';
        case '3':
          return '캐니다 Halifax';
        case '4':
          return '태국 Thai-Swedish Assembly Co. Ltd.';
        case '5':
          return '말레이시아 볼보';
        case '6':
          return '오스트리아 볼보';
        case '7':
          return '인도네시아 볼보';
        case 'B':
          return '중국 Chengdu';
        case 'D':
          return '이탈리아 볼보';
        case 'E':
          return '싱가포르 볼보';
        case 'F':
          return '네덜란드 Born';
        case 'G':
          return '미국 Charleston';
        case 'J':
          return '스웨덴 Uddevalla';
        case 'P':
          return '중국 Daqing';
        case 'L':
          return '중국 Chengdu';
        default:
          return '';
      }
    } else if (brandName === '포르쉐') {
      switch (vin[10]) {
        case 'S':
          return '독일 Zuffenhausen';
        case 'N':
          return '독일 Neckarsulm';
        case 'L':
          return '독일 Leipzig';
        case 'U':
          return '핀란드 Uusikaupunki';
        case 'K':
          return '독일 Osnabrück';
        case 'D':
          return '슬로바키아 Bratislava';
        default:
          return '';
      }
    } else if (brandName === '폭스바겐' || brandName === '아우디') {
      switch (vin[10]) {
        case 'A':
          return '독일 Ingolstadt';
        case 'B':
          return '벨기에 Brussels';
        case 'C':
          return '미국 Chattanooga';
        case 'D':
          return '슬로바키아 Bratislava';
        case 'E':
          return '독일 Emden';
        case 'F':
          return '브라질 Ipiranga / Resende';
        case 'G':
          return '오스트리아 Graz';
        case 'H':
          return '독일 Hanover';
        case 'K':
          return '독일 Osnabrück';
        case 'L':
          return '나이지리아 Lagos';
        case 'M':
          return '멕시코 Puebla';
        case 'N':
          return '독일 Neckarsulm';
        case 'P':
          return '독일 Mosel';
        case 'P':
          return '브라질 Anchieta';
        case 'R':
          return '스페인 Martorell';
        case 'S':
          return '독일 Salzgitter';
        case 'T':
          return '브라질 Taubaté';
        case 'U':
          return '남아프리카공화국 Uitenhage';
        case 'V':
          return '포르투갈 Palmela';
        case 'W':
          return '독일 Wolfsburg';
        case 'X':
          return '폴란드 Poznan';
        case 'Y':
          return '스페인 Pamplona';
        case '1':
          return '헝가리 Győr';
        case '2':
          return '중국 Anting';
        case '3':
          return '중국 Changchun';
        case '4':
          return '브라질 Curitiba';
        case '6':
          return '독일 Düsseldorf (Mercedes-Benz)';
        case '7':
          return brandName === '폭스바겐'
            ? '독일 Ludwigsfelde (Mercedes-Benz)'
            : '독일 Heilbronn';
        case '8':
          return '독일 Dresden';
        case '8':
          return '아르헨티나 General Pacheco';
        case '9':
          return '폴란드 Wrzesnia';
        default:
          return '';
      }
    } else if (brandName === '미니') {
      return '영국 Oxford';
    } else if (brandName === '롤스로이스') {
      return '영국 Goodwood';
    } else if (brandName === '페라리') {
      return '이탈리아 Maranello';
    } else if (brandName === '혼다') {
      switch (vin[10]) {
        case 'B':
          return '미국 Alabama Lincoln';
        case 'E':
          return '미국 Indiana Greensburg';
        case 'F':
          return '대만 Pingtung';
        case 'G':
          return '멕시코 Jalisco El Salto';
        case 'H':
          return '캐니다 Ontario Alliston';
        case 'J':
          return '인도네시아 West Java Karawang';
        case 'L':
          return '미국 Ohio East Liberty';
        case 'M':
          return '멕시코 Guanajuato Celaya';
        case 'P':
          return '태국 Ayutthaya';
        case 'S':
          return '일본 Mie Suzuka';
        case 'T':
          return '일본 Tochigi Takanezawa';
        case 'U':
          return '영국 Wiltshire Swindon';
        case 'W':
          return '터키 Kocaeli Gebze';
        case 'X':
          return '일본 Saitama Yorii';
        case 'Y':
          return '미국 Ohio Marysville';
        case 'Z':
          return '브라질 Sao Paulo Sumaré';
        case '2':
        case '3':
          return '중국 Guangzhou';
        case '4':
          return '인도 Rajasthan Tapukara';
        case '7':
          return '일본 Kanagawa Fujisawa';
        default:
          return '';
      }
    } else if (brandName === '토요타' || brandName === '렉서스') {
      switch (vin[10]) {
        case '0':
          return '일본 토요타';
        case 'A':
          return '일본 Motomachi';
        case 'C':
          return '캐나다 Cambridge';
        case 'D':
          return '일본 Obu Nagakusa';
        case 'E':
          return '영국 Derbyshire Burnaston';
        case 'J':
          return '일본 Takaoka';
        case 'K':
          return '일본 Hamura';
        case 'M':
          return '멕시코 Baja CA Tijuana';
        case 'N':
          return '체코 Kolín';
        case 'P':
          return '미국 Blue Springs';
        case 'R':
          return '미국 Lafayette';
        case 'S':
          return '미국 Princeton';
        case 'V':
          return '미국 Huntsville';
        case 'U':
          return '미국 Georgetown';
        case 'W':
          return '캐나다 Woodstock';
        case 'X':
          return '미국 San Antonio';
        case 'Z':
          return '미국 Fremont';
        default:
          return '';
      }
    } else if (brandName === '애스턴마틴') {
      switch (vin[10]) {
        case 'A':
          return '오스트리아 Graz';
        case 'G':
          return '영국 Gaydon';
        default:
          return '';
      }
    } else if (brandName === '벤틀리') {
      return '영국 Crewe';
    } else if (brandName === '맥라렌') {
      return '영국 Woking';
    } else if (brandName === '포드' || brandName === '링컨') {
      switch (vin[10]) {
        case 'A':
          return '미국 Atlanta';
        case 'B':
          return '캐나다 Oakville';
        case 'C':
          return '캐나다 Ontario';
        case 'D':
          return '미국 Ohio';
        case 'E':
          return '미국 Kentucky';
        case 'F':
          return '미국 Dearborn';
        case 'G':
          return '미국 Chicago';
        case 'H':
          return '미국 Lorain';
        case 'J':
          return '멕시코 Monterrey';
        case 'K':
          return '미국 Kansas City';
        case 'L':
          return '미국 Michigan';
        case 'M':
          return '멕시코 Cuautitlán';
        case 'N':
          return '미국 Norfolk';
        case 'P':
          return '미국 Twin Cities';
        case 'R':
          return '멕시코 Hermosillo';
        case 'S':
          return '미국 Allen Park';
        case 'T':
          return '미국 Edison';
        case 'U':
          return '미국 Louisville';
        case 'V':
          return '미국 Kentucky';
        case 'W':
          return '미국 Wayne';
        case 'X':
          return '캐나다 St. Thomas';
        case 'Y':
          return '미국 Wixom';
        case 'Z':
          return '미국 St. Louis';
        case '0':
          return '미국 Detroit';
        case '5':
          return '미국 Flat Rock';
        default:
          return '';
      }
    } else if (
      brandName === '크라이슬러' ||
      brandName === '지프' ||
      brandName === '닷지' ||
      brandName === '피아트'
    ) {
      switch (vin[10]) {
        case 'A':
          return '미국 Auburn Hills';
        case 'B':
          return '이탈리아 Milan';
        case 'C':
          return '미국 Detroit';
        case 'D':
          return '미국 Belvidere';
        case 'E':
          return '멕시코 Saltillo';
        case 'F':
          return '인도 Ranjangaon';
        case 'G':
          return '멕시코 Saltillo';
        case 'H':
          return '캐나다 Brampton';
        case 'J':
          return '일본 Sakahogi';
        case 'K':
          return '브라질 Goiana';
        case 'L':
          return '미국 Toledo';
        case 'M':
          return '멕시코 Mexico City';
        case 'N':
          return '미국 Sterling Heights';
        case 'P':
          return '이탈리아 Melfi';
        case 'R':
          return '캐나다 Windsor';
        case 'S':
          return '미국 Warren';
        case 'T':
          return '미국 Toledo';
        case 'U':
          return '일본 Kurashiki';
        case 'V':
          return '미국 Detroit';
        case 'W':
          return '미국 Toledo';
        case 'X':
          return '독일 Osnabrueck';
        case 'Y':
          return '일본 Nagoya';
        case 'Z':
          return '일본 Okazaki';
        case '2':
          return '프랑스 Maubeuge';
        case '3':
          return "이탈리아 Pomigliano d'Arco";
        case '4':
          return '이집트 Cairo';
        case '5':
          return '독일 Dusseldorf';
        case '6':
          return '대만 Taoyuan';
        case '7':
          return '중국 Beijing';
        case '8':
          return '미국 Detroit';
        case '9':
          return '브라질 Betim';
        case '0':
          return '중국 Fuzhou';
        default:
          return '';
      }
    }

    return '';
  };
