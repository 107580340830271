import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';
import Image from 'next/image';
import styled, { css } from 'styled-components';

import InfoIcon from '@public/icon/Toast/info.svg';
import CheckIcon from '@public/icon/Toast/check.svg';
import CautionIcon from '@public/icon/Toast/caution.svg';
import PointIcon from '@public/icon/Toast/new_point.svg';

type TOAST_ICON_TYPE = 'info' | 'check' | 'caution' | 'point' | 'none';

const TOAST_DURATION = 2000;

interface ToastProps {
  message: string;
  icon?: TOAST_ICON_TYPE;
  onClose?: () => void;
}

const Toast = forwardRef(({ icon, message, onClose }: ToastProps, ref) => {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setPortalRoot(document.getElementById('toast'));
  }, []);

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (visible)
      setTimeout(() => {
        close();
      }, TOAST_DURATION);
  }, [visible]);

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [],
  );

  const getIcon = () => {
    switch (icon) {
      case 'info':
        return <Icon src={InfoIcon} alt='info' />;
      case 'check':
        return <Icon src={CheckIcon} alt='check' />;
      case 'caution':
        return <Icon src={CautionIcon} alt='caution' />;
      case 'point':
        return <Icon src={PointIcon} alt='point' />;
      case 'none':
        return <></>;
      default:
        return <Icon src={InfoIcon} alt='info' />;
    }
  };

  if (!portalRoot) return <></>;

  if (portalRoot && visible)
    return createPortal(
      <BackDrop>
        <Background hide={!visible}>
          <Container>
            <IconWrapper>{getIcon()}</IconWrapper>
            <Message>{message}</Message>
          </Container>
        </Background>
      </BackDrop>,
      portalRoot,
    );
  return <></>;
});
export default Toast;

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const Background = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  animation: show_toast 1s ease forwards;

  ${({ hide }) =>
    hide &&
    css`
      animation: hide_toast 1s ease forwards;
    `}

  @keyframes show_toast {
    0% {
      transform: translateX(100%);
    }

    50% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(-20px);
    }
  }

  @keyframes hide_toast {
    0% {
      transform: translateX(-20px);
    }

    50% {
      transform: translateX(-10%);
    }

    100% {
      opacity: 0;
      pointer-events: none;
      transform: translateX(100%);
    }
  }
`;

const Container = styled.div`
  background-color: #181818bf;
  border-radius: 4px;
  z-index: 100;
  padding: 20px;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;

const Icon = styled(Image).attrs(({ src, alt }) => ({
  src,
  alt,
  layout: 'fill',
}))``;

const Message = styled.h5`
  font-size: 14px;
  color: ${({ theme }) => theme.base.white};
  margin-left: 6px;
  flex: 1;
`;
