import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  RECALL,
  OPTION,
  INSURANCE,
  TRANSACTION,
  SPEC,
  GRADES,
  REGISTRATION,
  INFORMATION,
  INFO_OPTIONS,
  SELECTED_OPTIONS,
  HIDDEN,
} from '@api/ApiPath';
import {
  postCarGrades,
  postCarInformation,
  postCarOption,
  postCarSpec,
  postRecordSummary,
  postGetchaOptions,
  postInfoOptions,
  postInsuranceInfo,
  postRecallInfo,
  postRegister,
} from '@api/Search';
import { isEmpty } from '@service/utils/validation';
import {
  SEARCH_ALL_RESPONSE,
  REG_RESPONSE,
  RECALL_RESPONSE,
  INSURANCE_RESPONSE,
  DEFAULT_REQUEST,
  GRADES_RESPONSE,
  OPTION_REQUEST,
  OPTION_RESPONSE,
  GRADES_REQUEST,
  RES_INFORMATION,
} from '@ts-types/search';
import { postTransaction } from '@api/Auth';
import { USER_INFO_REQUEST } from '@ts-types/auth';

/**
 * transaction id 부여받기 = 조회건수 차감
 */
const useGetTransaction = ({
  carNo,
  token,
  ownerName,
  isHold,
  successFn,
  settleFn,
}: USER_INFO_REQUEST) => {
  const { data, isLoading, isError, error } = useQuery(
    [TRANSACTION, token, carNo, ownerName],
    () => postTransaction({ token, carNo, ownerName }),
    {
      retry: 1,
      enabled: !isEmpty(token) && !isEmpty(carNo) && !isHold,
      staleTime: 0,
      onSuccess: () => {
        successFn?.();
      },
      onSettled: (res) => {
        settleFn?.(res?.result);
      },
    },
  );

  return {
    data: data?.result,
    isLoading,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 차량 정보 기본 -> 사용불가
 */
const useCheckInformation = ({ carNo }) => {
  const { data, isLoading, isError, isRefetching, refetch, error } = useQuery<
    RES_INFORMATION,
    AxiosError
  >([INFORMATION, carNo], () => postCarInformation({ carNo }), {
    retry: 1,
    enabled: !isEmpty(carNo),
    staleTime: 360000,
  });

  return {
    data: data?.result,
    refetch,
    isLoading,
    isRefetching,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 차량 원부조회
 */
const useCheckSpec = ({
  carNo,
  ownerName,
  transactionId,
  token,
}: DEFAULT_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch, error } = useQuery<
    SEARCH_ALL_RESPONSE,
    AxiosError
  >(
    [SPEC, transactionId, carNo, ownerName],
    () => postCarSpec({ carNo, ownerName, transactionId, token }),
    {
      retry: 1,
      enabled: !isEmpty(carNo) && !isEmpty(transactionId) && !isEmpty(token),
      staleTime: 360000,
    },
  );

  // console.log("🚀 ~ 스펙 data:", data?.result)
  return {
    data: data?.result,
    refetch,
    isLoading,
    isRefetching,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 숨은이력 조회
 * recall, insurance 통합
 * postRecordSummary
 */

export const useRecordSummary = ({
  carNo,
  ownerName,
  transactionId,
  token,
}: DEFAULT_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch, error } = useQuery(
    [HIDDEN, transactionId, carNo, ownerName],
    () => postRecordSummary({ carNo, ownerName, transactionId, token }),
    {
      retry: 1,
      enabled: !isEmpty(carNo) && !isEmpty(transactionId) && !isEmpty(token),
      staleTime: 360000,
    },
  );

  // console.log("🚀 ~ 숨은이력 data:", data?.result)
  return {
    data: data?.result,
    refetch,
    isLoading,
    isRefetching,
    isError,
    error: error?.response?.data,
  };
};


/**
 * 차량 리콜 조회
 */
const useCheckRecall = ({
  carNo,
  token,
  ownerName,
  transactionId,
}: DEFAULT_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery<
    RECALL_RESPONSE,
    AxiosError
  >(
    [RECALL, transactionId, carNo, ownerName],
    () => postRecallInfo({ carNo, token, ownerName, transactionId }),
    {
      retry: 1,
      enabled: !isEmpty(carNo) && !isEmpty(transactionId) && !isEmpty(token),
      staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
  };
};

/**
 * 차량 보험이력 조회
 */
const useCheckInsurance = ({
  carNo,
  token,
  ownerName,
  transactionId,
}: DEFAULT_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch, error } = useQuery<
    INSURANCE_RESPONSE,
    AxiosError
  >(
    [INSURANCE, transactionId, carNo, ownerName],
    () => postInsuranceInfo({ carNo, token, ownerName, transactionId }),
    {
      retry: 1,
      enabled: !isEmpty(carNo) && !isEmpty(transactionId) && !isEmpty(token),
      staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 차량 grade 조회
 */
const useCheckGrades = ({
  carNo,
  token,
  ownerName,
  transactionId,
  isHasRegData,
}: GRADES_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery<
    GRADES_RESPONSE,
    AxiosError
  >(
    [GRADES, transactionId, carNo, ownerName],
    () => postCarGrades({ carNo, token, ownerName, transactionId }),
    {
      retry: 1,
      enabled:
        !isEmpty(carNo) &&
        !isEmpty(ownerName) &&
        !isEmpty(transactionId) &&
        !isEmpty(token) &&
        isHasRegData,
      staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
  };
};

/**
 * 차량 원부 조회
 */
const useCheckRegister = ({
  carNo,
  token,
  ownerName,
  transactionId,
}: DEFAULT_REQUEST) => {
  const { data, isLoading, isError, error, isRefetching, refetch } = useQuery<
    REG_RESPONSE,
    AxiosError
  >(
    [REGISTRATION, transactionId, carNo, ownerName],
    () => postRegister({ carNo, token, ownerName, transactionId }),
    {
      retry: 1,
      enabled:
        !isEmpty(carNo) &&
        !isEmpty(ownerName) &&
        !isEmpty(transactionId) &&
        !isEmpty(token),
      // staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
    error: error?.response?.data,
  };
};

/**
 * 기본 옵션 정보 조회
 */
const useInfoOptions = ({ firstRegistrationDate, gradeId }) => {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery(
    [INFO_OPTIONS, firstRegistrationDate, gradeId],
    () =>
      postInfoOptions({
        firstRegistrationDate,
        gradeId,
      }),
    {
      retry: 1,
      enabled: !isEmpty(firstRegistrationDate) && !isEmpty(gradeId),
      staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
  };
};

/**
 * 기본 출고시 옵션 정보 조회
 */
export const useGetchaInfoOptions = ({ carNo, hasInfoData = false }) => {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery(
    [SELECTED_OPTIONS, carNo],
    () =>
      postGetchaOptions({
        carNo,
      }),
    {
      retry: 1,
      enabled: !isEmpty(carNo) && hasInfoData,
      // staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
  };
};

/**
 * 옵션 정보 조회
 */
const useCheckOption = ({
  carNo,
  token,
  ownerName,
  transactionId,
  gradeId,
}: OPTION_REQUEST) => {
  const { data, isLoading, isError, isRefetching, refetch } = useQuery<
    OPTION_RESPONSE,
    AxiosError
  >(
    [OPTION, transactionId, gradeId, carNo, ownerName],
    () =>
      postCarOption({
        carNo,
        ownerName,
        token,
        transactionId,
        gradeId,
      }),
    {
      retry: 1,
      enabled:
        !isEmpty(carNo) &&
        !isEmpty(ownerName) &&
        !isEmpty(transactionId) &&
        !isEmpty(token) &&
        !isEmpty(gradeId),
      staleTime: 360000,
    },
  );

  return {
    data: data?.result,
    refetch,
    isRefetching,
    isLoading,
    isError,
  };
};

export {
  useCheckInformation,
  useCheckSpec,
  useCheckRecall,
  useCheckInsurance,
  useGetTransaction,
  useCheckGrades,
  useCheckRegister,
  useCheckOption,
  useInfoOptions,
};
