import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import { theme } from '@styles/global';

interface TopBarProps {
  title?: string;
  leftIcon?: string | null;
  leftIconPressed?: () => void;
  textButtonLabel?: string;
  textButtonPressed?: () => void;
  rightFirstIcon?: string;
  rightFirstPressed?: () => void;
  rightSecondIcon?: string;
  rightSecondPressed?: () => void;
  hasBorder?: boolean;
  color?: string;
  bgColor?: string;
}

const TopBar = ({
  title = '',
  leftIcon = 'pictogram-regular-arrow_header_left',
  leftIconPressed = () => {},
  textButtonLabel = '',
  textButtonPressed = () => {},
  rightFirstIcon = '',
  rightFirstPressed = () => {},
  rightSecondIcon = '',
  rightSecondPressed = () => {},
  hasBorder = false,
  color,
  bgColor = theme.base.white,
  ...props
}: TopBarProps) => {
  const onLeftButtonPress = () => {
    leftIconPressed();
  };

  const getLeftContainerWidth = () => {
    if (textButtonLabel) {
      return 84;
    }
    if (rightFirstIcon && rightSecondIcon) {
      return 80;
    }
    return 40;
  };

  return (
    <Container hasBorder={hasBorder} bgColor={bgColor} {...props}>
      <LeftButtonContainer width={getLeftContainerWidth()}>
        {leftIcon && (
          <button onClick={onLeftButtonPress}>
            <Icon name={leftIcon} />
          </button>
        )}
      </LeftButtonContainer>

      {!!title && <Title color={color}>{title}</Title>}

      <RightButtonContainer>
        {!!textButtonLabel && (
          <button onClick={textButtonPressed}>
            <TextButton>{textButtonLabel}</TextButton>
          </button>
        )}

        {!!rightFirstIcon && (
          <RightButton onClick={rightFirstPressed}>
            <Icon name={rightFirstIcon} />
          </RightButton>
        )}

        {!!rightSecondIcon && (
          <RightButton onClick={rightSecondPressed}>
            <Icon name={rightSecondIcon} />
          </RightButton>
        )}
      </RightButtonContainer>
    </Container>
  );
};

export default TopBar;

interface LeftButtonStyle {
  width: string;
}

const Container = styled.div`
  width: 100%;
  height: 56px;
  z-index: 10;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: space-between;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;

  ${({ theme, hasBorder }) =>
    hasBorder &&
    css`
      border-bottom: 1px solid ${theme.base.grey200};
    `};
`;

const TextButton = styled.div`
  font-family: SpoqaHanSans-Regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -1px;
  width: 68px;
  color: ${(props) => props.theme.base.grey500};
  text-align: right;
  margin-left: 16px;
`;

const LeftButtonContainer = styled.div<LeftButtonStyle>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  width: ${(props) => props.width + 16}px;
`;

const RightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 56px;
  height: 100%;
  padding-right: 16px;
`;

const RightButton = styled.button`
  margin-left: 16px;
`;

const Title = styled.h4`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${({ color }) => color || '#121212'};
`;
