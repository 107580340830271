export const CARFAX = 'api/carfax';

// 약관 버전 & 파일
export const TERMS = 'terms';

// 검색기록 삭제
export const DELETE_ALL = 'user/search/all';
export const DELETE_ONE = 'user/search';

// 결과페이지 (반드시 순서대로 호출해야함)
// 0번 순서 호출
export const TRANSACTION = 'transaction';

// 1번 순서 호출
export const INSURANCE = 'insurance';
export const RECALL = 'recall';
export const REGISTRATION = 'registration';
export const SPEC = 'spec';
export const INFORMATION = 'information';
export const INFO_OPTIONS = 'options';
export const SELECTED_OPTIONS = 'information/options';

// 2번 순서 호출
export const GRADES = 'spec/grades'; // reg data 있어야 호출가능
export const OPTION = 'spec/options';

// auth
export const SEND_CODE = 'user/code/send';
export const CHECK_CODE = 'user/code/check';
export const SIGN_UP = 'user/signup';
export const LOGIN = 'user/login';
export const USER_INFO = 'user';

// 숨은 이력
export const HIDDEN = 'record-summary';