import styled, { css } from 'styled-components';

import { addComma, convertNumToKrw } from '@service/utils/number';
import {
  BorderLine,
  Flex,
  FlexStyle,
  MobileSection,
  MobileTitle,
  Section,
  Space,
} from '@styles/carFaxResultStyle';
import { NotoBold, NotoMedium, NotoRegular } from '@styles/global';
import { SpecProps} from '@components/carfax/CarDetailContent';
import { replaceRomanNumberUnicodeToAlphabet } from '@service/utils/string';
import Skeleton from '@components/common/Skeleton';
import { getWindowDimension } from '@service/utils/getWindowDimestion';
import { useState } from 'react';

export interface PriceOptionProps extends SpecProps {
  isSpecLoading: boolean;
}

const MobilePriceOption = ({ specData, isSpecLoading }: PriceOptionProps) => {
  const { width } = getWindowDimension();
  const [showAll, setShowAll] = useState<boolean>(false);

  const guessSelectedOptionPriceList = (
    //selectedOptionName: string,
    optionList: Array<any>,
    selectedOptionList: Array<any>,
  ) => {
    if (!optionList || optionList?.length <= 0) {
      if (selectedOptionList && selectedOptionList?.length > 0) {
        return selectedOptionList?.map((item) => {
          return {
            optName: item,
            optPrice: -1,
          };
        });
      } else return [];
    }
    if (!selectedOptionList || selectedOptionList.length <= 0) return [];



    /* 제조사에서 가저온 옵션중 공백 및 특정 문구는 삭제하고 비교 */
    /* 옵션명에 로마숫자가 포함되어 있을 경우 로마숫자를 알파벳으로 변경하여 비교 */
    let trimmedSelectedOptionList = selectedOptionList?.map((item: string) => {
      return replaceRomanNumberUnicodeToAlphabet(
        item?.replace(/ /g, '')
          .replace(/\(울산희망시\)/g, '')
          .replace(/드라이빙어시스턴트/, '드라이빙어시스턴스')
          .replace(/썬루프/, '선루프')
          .replace(/스마트센스III/, '스마트센스3')
          .replace(/스마트센스Ⅲ/, '스마트센스3')
          .replace(/스마트센스II/, '스마트센스2')
          .replace(/스마트센스Ⅱ/, '스마트센스2')
          .replace(/스마트센스I/, '스마트센스1')
          .replace(/스마트센스Ⅰ/, '스마트센스1')
          .replace(/인포테인먼트내비IV/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비Ⅳ/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비III/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비Ⅲ/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비II/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비Ⅱ/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비I/, '인포테인먼트내비1')
          .replace(/인포테인먼트내비Ⅰ/, '인포테인먼트내비1')
          .replace(/셀렉션IV/, '셀렉션4')
          .replace(/셀렉션Ⅳ/, '셀렉션4')
          .replace(/셀렉션III/, '셀렉션3')
          .replace(/셀렉션Ⅲ/, '셀렉션3')
          .replace(/셀렉션II/, '셀렉션2')
          .replace(/셀렉션Ⅱ/, '셀렉션2')
          .replace(/셀렉션I/, '셀렉션1')
          .replace(/셀렉션Ⅰ/, '셀렉션1')
          .replace(/패키지IV/, '패키지4')
          .replace(/패키지Ⅳ/, '패키지4')
          .replace(/패키지III/, '패키지3')
          .replace(/패키지Ⅲ/, '패키지3')
          .replace(/패키지II/, '패키지2')
          .replace(/패키지Ⅱ/, '패키지2')
          .replace(/패키지I/, '패키지1')
          .replace(/패키지Ⅰ/, '패키지1')
      );
    });

    trimmedSelectedOptionList = trimmedSelectedOptionList.map(item => item.split('+')).flat();

    /* 비교할 전체 선택 옵션 리스트 */
    let trimmedOptionList = optionList?.map((item, index) => {
      return replaceRomanNumberUnicodeToAlphabet(
        item?.optName?.replace(/ /g, '')
          .replace(/내비게이션선택시/, '')
          .replace(/스마트센스III/, '스마트센스3')
          .replace(/스마트센스Ⅲ/, '스마트센스3')
          .replace(/스마트센스II/, '스마트센스2')
          .replace(/스마트센스Ⅱ/, '스마트센스2')
          .replace(/스마트센스I/, '스마트센스1')
          .replace(/스마트센스Ⅰ/, '스마트센스1')
          .replace(/인포테인먼트내비IV/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비Ⅳ/, '인포테인먼트내비4')
          .replace(/인포테인먼트내비III/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비Ⅲ/, '인포테인먼트내비3')
          .replace(/인포테인먼트내비II/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비Ⅱ/, '인포테인먼트내비2')
          .replace(/인포테인먼트내비I/, '인포테인먼트내비1')
          .replace(/인포테인먼트내비Ⅰ/, '인포테인먼트내비1')
          .replace(/셀렉션IV/, '셀렉션4')
          .replace(/셀렉션Ⅳ/, '셀렉션4')
          .replace(/셀렉션III/, '셀렉션3')
          .replace(/셀렉션Ⅲ/, '셀렉션3')
          .replace(/셀렉션II/, '셀렉션2')
          .replace(/셀렉션Ⅱ/, '셀렉션2')
          .replace(/셀렉션I/, '셀렉션1')
          .replace(/셀렉션Ⅰ/, '셀렉션1')
          .replace(/패키지IV/, '패키지4')
          .replace(/패키지Ⅳ/, '패키지4')
          .replace(/패키지III/, '패키지3')
          .replace(/패키지Ⅲ/, '패키지3')
          .replace(/패키지II/, '패키지2')
          .replace(/패키지Ⅱ/, '패키지2')
          .replace(/패키지I/, '패키지1')
          .replace(/패키지Ⅰ/, '패키지1')
          .replace(/미쉐린타이어/, '인치')
          .replace(/콘티넨탈타이어/, '인치')
          .replace(/피렐리타이어/, '인치')
      );
    });

    let guessedSelectedOptionsFromOptionList =
      trimmedOptionList?.map((item, index) => {
        const subItemLength = item.split('+').length;
        console.log(item.split('+'));
        const filteredItemList = item.split('+').map((subItem, subIndex) => {
          const selectedItem = trimmedSelectedOptionList?.filter((selectedItem) => subItem.includes(selectedItem) || selectedItem.includes(subItem));
          console.log('selected item : ' + selectedItem)
          return selectedItem[0] ?? null;
        });

        const filteredList = filteredItemList?.filter((item) => item);

        console.log('filtered list : ', filteredList);

        if (filteredList && filteredList.length === subItemLength) {
          console.log('same : ', item.split('+'), optionList[index]);
          return optionList[index];
        }
      });

    return guessedSelectedOptionsFromOptionList?.filter((option, _, array) =>
      !array.some(otherOption =>
        otherOption?.optName !== option?.optName && otherOption?.optName?.includes(option?.optName)
      )
    ).filter((item) => item);
  };

  // const guessedOptionPriceList = guessSelectedOptionPriceList(selectedOptions, optData?.selectedOptions?.options);

  // const guessOptionPrice = (
  //   selectedOptionName: string,
  //   optionList: Array<any>,
  // ) => {
  //   /* 제조사에서 가저온 옵션중 공백 및 특정 문구는 삭제하고 비교 */
  //   /* 옵션명에 로마숫자가 포함되어 있을 경우 로마숫자를 알파벳으로 변경하여 비교 */
  //   const trimmedOptionName = replaceRomanNumberUnicodeToAlphabet(
  //     selectedOptionName.replace(/ /g, '').replace(/\(울산희망시\)/g, ''),
  //   );

  //   if (optionList?.length > 0) {
  //     const option = optionList?.find((el) =>
  //       el?.optName.replace(/ /g, '').includes(trimmedOptionName),
  //     );
  //     if (!option) return -1;
  //     return option?.optPrice;
  //   }
  //   return 0;
  // };

  if (isSpecLoading)
    return (
      <Section>
        <MobileTitle>가격 정보</MobileTitle>
        <Skeleton width='100%' height={598} />
      </Section>
    );

  return (
    <PriceWrapper>
      <MobileTitle>가격 정보</MobileTitle>

      <Apart>
        <Label>출고가격</Label>
        <Flex>
          <h4 className='red price-value'>
            {+specData.carSpec.salePrice > 0 ? convertNumToKrw(+specData.carSpec.salePrice) : '-'}
          </h4>
          <p className='unit'>만원</p>
        </Flex>
      </Apart>
      <Apart>
        <Label>기본가격</Label>
        <Flex>
          <h4 className='price-value'>
            -
          </h4>
          <p className='unit'>만원</p>
        </Flex>
      </Apart>
      <Apart>
        <Label>출고시 옵션가</Label>
        <Flex>
          <h4 className='price-value'>-</h4>
          <p className='unit'>만원</p>
        </Flex>
      </Apart>

      <BorderLine />
      <Space h={20} />
      <Label>출고시 옵션</Label>
      {specData.carAddOptions?.length > 0 ? (
        <>
          <Space h={15} />
          {specData.carAddOptions?.map((opt, i) => {
            return (
              <Apart key={`${opt}-${i}`}>
                <p className='regular'>{opt?.optionName}</p>
              </Apart>
            );
          })}
        </>
      ) : (
        <Empty>제공 정보가 없습니다.</Empty>
      )}

      <BorderLine />
      <Space h={20} />
      <Label>선택품목</Label>
      {specData.carAddOptions?.length === 0 ? (
        <Empty>제공 정보가 없습니다.</Empty>
      ) : (
        <>
          <Space h={15} />
          {specData.carAddOptions?.map((opt) => {
            return (
              <Apart w={width} key={opt.optionName}>
                <p className='regular'>{opt.optionName}</p>
                <h5>
                  {String(opt?.optionPrice)?.includes(',')
                    ? opt?.optionPrice
                    : addComma(opt?.optionPrice)}{' '}
                  원
                </h5>
              </Apart>
            );
          })}
        </>
      )}

      <BorderLine />
      <Space h={20} />
      <Label>기본품목</Label>
      {specData.carOptions?.length === 0 ? (
        <Empty>제공 정보가 없습니다.</Empty>
      ) : (
        <>
          <Space h={15} />
          {specData.carOptions?.map((opt) => {
            return (
              <Apart w={width} key={opt.optionName}>
                <h5>{opt.parts}</h5>
                <p className='regular'>{opt.optionName}</p>
              </Apart>
            );
          })}
        </>
      )}
    </PriceWrapper>
  );
};

export default MobilePriceOption;

const PriceWrapper = styled(MobileSection)``;

const Label = styled.p`
  ${NotoMedium}
  font-size:12px;
`;

const BasicOption = styled.div`
  position: relative;

  .blur {
    visibility: ${({ showAll }) => (showAll ? 'hidden' : 'visible')};
    position: absolute;
    bottom: 60px;
    height: 240px;
    width: 100%;
    left: 5px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
`;

const Apart = styled.div`
  ${FlexStyle}
  justify-content: space-between;
  margin-bottom: 16px;

  h4 {
    ${NotoBold}
    font-size:16px;
  }

  h5 {
    ${NotoBold}
    font-size:12px;
  }

  .regular {
    ${NotoRegular}
    font-size: 12px;
    line-height: 16px;
    max-width: ${({ w }) => w - 40 - 100}px;
  }

  .unit {
    ${NotoRegular}
    font-size:16px;
    margin-left: 4px;
  }

  .red {
    color: ${({ theme }) => theme.primary};
  }

  .price-value {
    font-family: DSEG7ClassicMini-Bold;
    letter-spacing: -1px;
  }

  .small {
    ${NotoBold}
    font-size:12px;
  }
`;

const GridByThree = styled.div`
  max-width: ${({ w }) => w}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #ffffff;
  row-gap: 24px;
  column-gap: 4px;
  margin-top: 20px;
  margin-bottom: 20px;

  div {
    width: 100%;
    padding: 0px 0px;
    min-height: 60px;

    ${FlexStyle}
    justify-content: flex-start;
    flex-direction: column;

    p {
      margin-top: 8px;
      text-align: center;
      ${NotoRegular}
      font-size:12px;
      text-align: center;
      line-height: 20px;
      color: #555555;
    }

    .option-price-value {
      ${NotoBold}
      color: ${({ theme }) => theme.primary};
      font-size: 12px;
    }
  }
`;

const Empty = styled.div`
  ${NotoRegular}
  font-size:12px;
  color: #7c7c7c;
  text-align: center;
  padding: 0  0 16px 0;
`;

const RestOption = styled.div`
  .list {
    padding: 6px 14px;
  }
  p {
    ${NotoRegular}
    font-size:12px;
    line-height: 24px;
    color: #555555;
    margin-bottom: 6px;
  }

  .title {
    color: #555555;
    ${NotoMedium}
    font-size:14px;
    margin-bottom: 12px;
  }
`;
