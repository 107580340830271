import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface SkeletonProps {
  width: number | string;
  height: number | string;
}

const Skeleton = ({ width, height }: SkeletonProps) => {
  return <SkeletonContainer width={width} height={height} />;
};

export default Skeleton;

const SkeletonContainer = styled.div<SkeletonProps>`
  display: inline-block;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.base.grey100};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: translateX(-100%);
    background-image: ${({ theme }) =>
      `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(239,239,239, 0.72) 30%,
    rgba(224,224,224,0.72) 60%,
      rgba(255, 255, 255, 0)
    );`};

    animation: shimmer 1.3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  & * {
    visibility: hidden;
  }
`;
