import React from 'react';
import styled from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import { useCheckRegister } from '@service/query/useSearch';
import { ContentProps, getFirstRegDate } from './CarDetailContent';
import {
  BorderLine,
  Empty,
  GreyBox,
  MobileSection,
  SectionTitle,
  Space,
  Value,
} from '@styles/carFaxResultStyle';
import { NotoMedium, NotoRegular } from '@styles/global';
import Skeleton from '@components/common/Skeleton';
import useUserStore from '@store/userStore';

const MobileCarSeizure = ({ carNo, ownerName, transactionId }: ContentProps) => {
  const { token } = useUserStore();

  const { data, isLoading, isRefetching, isError } = useCheckRegister({
    carNo,
    ownerName,
    transactionId,
    token,
  });

  const seizureData = data?.contents;

  const pawnCnt = seizureData?.filter((d) => d?.type === '저당')?.length || 0;
  const seizureCnt = seizureData?.filter((d) => d?.type === '압류')?.length || 0;

  if ((isLoading || isRefetching) && !isEmpty(ownerName))
    return (
      <MobileSection>
        <SectionTitle>
          저당 {pawnCnt} / 압류 {seizureCnt}
        </SectionTitle>
        <Skeleton width='100%' height={160} />
      </MobileSection>
    );

  if (!isLoading && isError)
    return (
      <MobileSection>
        <SectionTitle>
          저당 {pawnCnt} / 압류 {seizureCnt}
        </SectionTitle>
        <BorderLine />
        <Empty>저당/압류 내역을 불러올 수 없습니다.</Empty>
      </MobileSection>
    );

  return (
    <MobileSection>
      <SectionTitle>
        저당 {pawnCnt} / 압류 {seizureCnt}
      </SectionTitle>

      {isEmpty(seizureData) || seizureData?.length === 0 ? (
        <Empty>저당/압류 내역을 불러올 수 없습니다.</Empty>
      ) : (
        <>
          {seizureData?.map((content) => {
            return (
              <div key={`${content?.type}-${content?.docNumber}`}>
                <Type>{content?.type}</Type>
                <BorderLine />
                <Grid>
                  <div>
                    <MobileGreyBox>등록일자</MobileGreyBox>
                    <SeizureValue>
                      <p>
                        {!isEmpty(content?.registerDate) && (
                          <>
                            {getFirstRegDate(content?.registerDate)?.yyyy}
                            {getFirstRegDate(content?.registerDate)?.mm
                              ? `.${getFirstRegDate(content?.registerDate)?.mm}`
                              : ''}
                            {getFirstRegDate(content?.registerDate)?.dd
                              ? `.${getFirstRegDate(content?.registerDate)?.dd}`
                              : ''}
                          </>
                        )}
                      </p>
                    </SeizureValue>
                  </div>
                  <div>
                    <MobileGreyBox>관리번호</MobileGreyBox>
                    <SeizureValue>
                      <p>{content?.docNumber}</p>
                    </SeizureValue>
                  </div>
                </Grid>
                <BorderLine />
                <MobileGreyBox>내용</MobileGreyBox>
                <SeizureValue isWide>
                  <p>
                    {!isEmpty(content?.contents) &&
                      content?.contents?.split('\n')?.map((line) => {
                        return <p>· {line}</p>;
                      })}
                  </p>
                </SeizureValue>
                <BorderLine />
                <Space h={20} />
              </div>
            );
          })}
        </>
      )}
    </MobileSection>
  );
};

export default MobileCarSeizure;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #d5d8de;
  column-gap: 1px;
`;

const Type = styled.p`
  ${NotoMedium}
  font-size:12px;
  color: #555555;
  margin-bottom: 12px;
`;

const SeizureValue = styled(Value)`
  ${NotoRegular}
  font-size:12px;
  color: #555555;
  align-items: flex-start;
  line-height: ${({ isWide }) => (isWide ? '180%' : '120%')};
  flex-direction: column;

  p {
    ${NotoRegular}
    font-size:12px;
    color: #555555;
    width: 100%;
    text-align: ${({ isWide }) => (isWide ? 'left' : 'center')};
  }
`;

const MobileGreyBox = styled(GreyBox)`
  height: 36px;
`;
