import React, { useState, useEffect, useRef, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import { useCheckInsurance } from '@service/query/useSearch';
import {
  BorderLine,
  Empty,
  FlexStyle,
  GreyBox,
  Grid,
  MobileSection,
  MoreBtn,
  MoreBtnWrapper,
  SectionTitle,
  Value,
} from '@styles/carFaxResultStyle';
import { NotoBold, NotoMedium, NotoRegular } from '@styles/global';
import Image from 'next/image';
import Modal from '@components/common/Modal';
import Skeleton from '@components/common/Skeleton';

import { NULLABLE } from '@ts-types/common';
import { addComma } from '@service/utils/number';
import ARROW_RIGHT from '@public/icon/right-arrow.png';
import useSearchStore from '@store/searchStore';
import { ContentProps } from './CarDetailContent';
import { ACCIDENT_DETAIL, ACCIDENT_HISTORY } from '@ts-types/search';
import useUserStore from '@store/userStore';
import DOT_ICON from '@public/icon/dot.png';
import Icon from '@components/common/Icon';

const TAB_LIST = {
  first: [
    { title: '사고 이력 요약', refIdx: 0 },
    { title: '자동차 일반 사양', refIdx: 1 },
    { title: '자동차 특수용도 이력', refIdx: 2 },
    { title: '자동차 번호/소유자 변경 이력', refIdx: 3 },
    { title: '특수사고 이력', refIdx: 4 },
  ],
  second: [
    { title: '보험사고 상세이력', refIdx: 5 },
    { title: '주행거리 이력', refIdx: 6 },
    { title: '차량가액 정보', refIdx: 7 },
    { title: '정보이용관련 유의사항', refIdx: 8 },
  ],
};

const PURPOSE_KO = {
  OFFICIAL: '관용',
  PERSONAL: '자가용',
  BUSINESS: '영업용',
  PRIVATE_TAXI: '개인택시',
};

const CARTYPE_KO = {
  PASSENGER_CAR: '승용',
  VAN: '승합',
  CARGO: '화물',
  SPECIAL: '특수',
};

const CHANGE_TYPE_KO = {
  CHANGE_CAR_NUMBER: '번호 변경',
  CHANGE_OWNER: '소유자 변경',
  FIRST_REGISTRATION: '최초등록',
};

export interface InsuranceCaseProps extends ContentProps {
  setOdometer: (value: string) => void;
}

const MobileCarInsurance = ({ carNo, ownerName, transactionId, setOdometer }: InsuranceCaseProps) => {
  const sectionRef = useRef<any>([]);
  const [openAccidentId, setOpenAccidentId] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(TAB_LIST.first[0].title);
  const [showInsurance, setShowInsurance] = useState<boolean>(true);

  const { token } = useUserStore();
  const { tags, setValue } = useSearchStore();

  const {
    data: insuranceData,
    isLoading: isInsuranceDataLoading,
    isRefetching,
    isError,
  } = useCheckInsurance({
    carNo,
    ownerName,
    token,
    transactionId,
  });

  useEffect(() => {
    if (insuranceData && insuranceData?.odometerHistory?.length > 0) {
      setOdometer(insuranceData?.odometerHistory[0]?.distance);
    }
  }, [insuranceData]);

  const scrollSection = (title: string, idx: number) => {
    setSelectedTab(title);
    setTimeout(() => {
      sectionRef.current[idx]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 100);
  };

  const getCount = (data: NULLABLE<number>) => {
    if (isEmpty(data) || data === 0) return <p className='none'>없음</p>;
    if (data > 0) return <p className='count'>{`${data}회 `}</p>;
  };

  const getBoolean = (data: boolean) => {
    if (isEmpty(data) || !data) return <p className='none'>없음</p>;
    return <p className='count'>있음</p>;
  };

  const historyList = insuranceData?.changeInfoHistory;
  const changedOwnerList = historyList?.filter(
    (item) => item.type === 'CHANGE_OWNER',
  );

  const changedNumberList = historyList?.filter(
    (item) => item.type === 'CHANGE_CAR_NUMBER',
  );

  const handleOpenAccident = (id: string) => {
    if (openAccidentId?.includes(id)) {
      setOpenAccidentId(openAccidentId.filter((el) => el !== id));
    } else setOpenAccidentId([...openAccidentId, id]);
  };

  const openInsuranceDetail = () => {
    setShowInsurance((prev) => !prev);
  };

  useEffect(() => {
    if (!isEmpty(insuranceData)) {
      setOpenAccidentId(
        insuranceData?.accidentHistory?.map((item) => item.accidentAt),
      );

      let adds: string[] = tags[`${carNo}-${ownerName}`] || [];

      const isClear =
        insuranceData?.collisionCount === 0 &&
        insuranceData?.liabilityCount === 0 &&
        insuranceData?.stolenCount === 0 &&
        insuranceData?.totalLossCount === 0 &&
        insuranceData?.floodedCount === 0;

      const isOneOwner =
        changedOwnerList?.length === 0 || isEmpty(changedOwnerList);

      if (isOneOwner) {
        adds = tags[`${carNo}-${ownerName}`]?.includes('1인 신조')
          ? adds
          : [...adds, '1인 신조'];
      }
      if (isClear) {
        adds = tags[`${carNo}-${ownerName}`]?.includes('무사고')
          ? adds
          : [...adds, '무사고'];
      }

      setValue('tags', { ...tags, [`${carNo}-${ownerName}`]: adds });
    }
  }, [insuranceData, carNo]);

  const calInsuranceCost = (title: string, item: ACCIDENT_DETAIL) => {
    if (item.repairEstimated > 0) {
      return (
        <InsuranceCase>
          <Total>
            <p>{title}</p>
            <h5>수리비용 : {addComma(item.repairEstimated)}원</h5>
          </Total>
          <InsuranceGrey>
            <div>
              <p>부품</p>
              <p className='black'>{addComma(item.partCost)}원</p>
            </div>
            <div>
              <p>공임</p>
              <p className='black'>{addComma(item.repairFee)}원</p>
            </div>
            <div>
              <p>도장</p>
              <p className='black'>{addComma(item.paintCost)}원</p>
            </div>
          </InsuranceGrey>
          <Detail>수리내역 : {item.repairDetail ?? '-'}</Detail>
        </InsuranceCase>
      );
    } else if (item.repairEstimated === 0) {
      return (
        <InsuranceCase>
          <Total>
            <p>{title}</p>
            <h5>
              지급보험금 :{' '}
              {item.insuranceAmount > 0 &&
                item.lossReserve === 0 &&
                `${addComma(item.insuranceAmount)}원`}
              {item.insuranceAmount > 0 &&
                item.lossReserve > 0 &&
                `${addComma(item.insuranceAmount)}원 + 미확정(${addComma(
                  item.lossReserve + item.undeterminedAmount,
                )}원)`}
              {item.insuranceAmount === 0 &&
                item.lossReserve > 0 &&
                `미확정(${addComma(
                  item.lossReserve + item.undeterminedAmount,
                )}원)`}
            </h5>
          </Total>
        </InsuranceCase>
      );
    }
  };
  const getUnsetCost = () => {
    // 미확정 금액 구하기
    let mine = false;
    let others = false;

    for (let i = 0; i < insuranceData?.accidentHistory?.length + 1; i++) {
      // 내 차 피해
      const fromAnother = insuranceData?.accidentHistory[i]?.fromAnotherInsurance;
      const fromSelf = insuranceData?.accidentHistory[i]?.fromSelfInsurance;
      const toOthers = insuranceData?.accidentHistory[i]?.damageToOthers;

      if (
        fromAnother?.accidentAmountReference === 'INSURANCE_AND_UNDETERMINED' ||
        fromAnother?.accidentAmountReference === 'UNDETERMINED' ||
        fromSelf?.accidentAmountReference === 'INSURANCE_AND_UNDETERMINED' ||
        fromSelf?.accidentAmountReference === 'UNDETERMINED'
      ) {
        mine = true;
      }

      if (
        toOthers?.accidentAmountReference === 'INSURANCE_AND_UNDETERMINED' ||
        toOthers?.accidentAmountReference === 'UNDETERMINED'
      ) {
        others = true;
      }
    }
    return { mine, others };
  };

  const isLoading = isInsuranceDataLoading || isRefetching;

  if (isLoading)
    return (
      <InsuranceSection>
        <SectionTitle>
          보험이력 <span className='red'>(유료)</span>
        </SectionTitle>
        <Skeleton width='100%' height={172} />
      </InsuranceSection>
    );

  if (!isLoading && isError)
    return (
      <InsuranceSection>
        <SectionTitle>
          보험이력 <span className='red'>(유료)</span>
        </SectionTitle>
        <BorderLine />
        <Empty>보험 이력을 불러올 수 없습니다.</Empty>
      </InsuranceSection>
    );

  return (
    <>
      <InsuranceSection>
        <SectionTitle>
          보험이력 <span className='red'>(유료)</span>
        </SectionTitle>
        <BorderLine />

        <GridByThree>
          <GridBlock>
            <SmallGreyBox>대여용도</SmallGreyBox>
            <CountValue>{getBoolean(insuranceData?.wasUsedForRental)}</CountValue>
          </GridBlock>
          <GridBlock>
            <SmallGreyBox>영업용도</SmallGreyBox>
            <CountValue>
              {getBoolean(insuranceData?.wasUsedForCommercial)}
            </CountValue>
          </GridBlock>
          <GridBlock>
            <SmallGreyBox>관용용도</SmallGreyBox>
            <CountValue>{getBoolean(insuranceData?.wasUsedAsOfficial)}</CountValue>
          </GridBlock>
        </GridByThree>
        <BorderLine />
        <Space height={20} />
        <BorderLine />

        <div>
          <SmallGreyBox>소유자 변경 이력</SmallGreyBox>
          <CountValue>{getCount(changedOwnerList?.length)}</CountValue>
        </div>
        <BorderLine />
        <Space height={20} />
        <BorderLine />

        <div>
          <SmallGreyBox>사고이력</SmallGreyBox>
          <GridByThree>
            <div className='history'>
              <p className='regular'>전손</p>
              <h6>
                {isEmpty(insuranceData?.totalLossCount) ||
                  insuranceData?.totalLossCount === 0
                  ? '없음'
                  : `${insuranceData?.totalLossCount}회`}
              </h6>
            </div>

            <div className='history'>
              <p className='regular'>도난</p>
              <h6>
                {isEmpty(insuranceData?.stolenCount) ||
                  insuranceData?.stolenCount === 0
                  ? '없음'
                  : `${insuranceData?.stolenCount}회`}
              </h6>
            </div>

            <div className='history'>
              <p className='regular'>침수</p>
              <h6>
                {isEmpty(insuranceData?.floodedCount) ||
                  insuranceData?.floodedCount === 0
                  ? '없음'
                  : `${insuranceData?.floodedCount}회`}
              </h6>
            </div>
          </GridByThree>
        </div>
        <BorderLine />
        <Space height={20} />
        <BorderLine />
        <InsuranceTwoCol>
          <div>
            <SmallGreyBox>내 차 피해</SmallGreyBox>
            <CostValue>
              <p className='cost'>{getCount(insuranceData?.collisionCount)}</p>
              {insuranceData?.collisionCount > 0 &&
                insuranceData?.collisionTotalAmount > 0 && (
                  <p className='cost'>
                    ({addComma(insuranceData?.collisionTotalAmount)}원)
                  </p>
                )}
            </CostValue>
          </div>

          <div>
            <SmallGreyBox>최초 보험 가입일</SmallGreyBox>
            <CostValue>
              <p className='cost'>
                {insuranceData?.firstInsuranceRegistrationDate}
              </p>
            </CostValue>
          </div>
        </InsuranceTwoCol>
        <BorderLine />
        <Space height={20} />
        <BorderLine />

        <div>
          <SmallGreyBox>자차보험 미가입 기간</SmallGreyBox>
          <UnregisteredValue>
            {isEmpty(insuranceData?.unregisteredPeriod) ||
              insuranceData?.unregisteredPeriod?.length === 0 ? (
              <p>-</p>
            ) : (
              <>
                {insuranceData?.unregisteredPeriod?.map((period, idx) => {
                  return <p key={`${period}-${idx}`}>{period}</p>;
                })}
              </>
            )}
          </UnregisteredValue>
        </div>
        <BorderLine />

        {showInsurance && (
          <InsuranceContent>
            <Section ref={(el) => (sectionRef.current[1] = el)}>
              <Space height={48} />
              <SectionTitle>1. 자동차 일반 사양</SectionTitle>
              <Space height={20} />
              <BorderLine />

              <>
                <MobileGrid>
                  <div>
                    <GreyBox>제조사</GreyBox>
                    <Value>{insuranceData?.brandName}</Value>
                  </div>
                  <div>
                    <GreyBox>차명</GreyBox>
                    <Value>{insuranceData?.carName}</Value>
                  </div>
                </MobileGrid>
                <BorderLine />
                <MobileGrid>
                  <div>
                    <GreyBox>연식</GreyBox>
                    <Value>{insuranceData?.carYear}</Value>
                  </div>
                  <div>
                    <GreyBox>사용연료</GreyBox>
                    <Value>{insuranceData?.fuel}</Value>
                  </div>
                </MobileGrid>
                <BorderLine />
                <MobileGrid>
                  <div>
                    <GreyBox>배기량</GreyBox>
                    <Value>
                      {insuranceData?.displacement &&
                        `${addComma(insuranceData?.displacement)} cc`}
                    </Value>
                  </div>
                  <div>
                    <GreyBox>차체형상</GreyBox>
                    <Value>
                      {isEmpty(insuranceData?.carShape)
                        ? '정보없음'
                        : insuranceData?.carShape}
                    </Value>
                  </div>
                </MobileGrid>
                <BorderLine />
                <MobileGrid>
                  <div>
                    <GreyBox>용도 및 차종</GreyBox>
                    <Value>
                      {PURPOSE_KO[insuranceData?.purpose]}{' '}
                      {CARTYPE_KO[insuranceData?.carType]}
                    </Value>
                  </div>
                  <div>
                    <GreyBox>최초 보험 가입일</GreyBox>
                    <Value>{insuranceData?.firstInsuranceRegistrationDate}</Value>
                  </div>
                </MobileGrid>
              </>

              <BorderLine />
            </Section>

            <Section ref={(el) => (sectionRef.current[3] = el)}>
              <Space height={48} />
              <SectionTitle>2. 자동차 번호/소유자 변경 이력</SectionTitle>
              <Space height={12} />
              <p className='grey'>
                * 소유자 변경이력 정보는{' '}
                <span className='black'>
                  개인 간의 소유 변경 이외에도 매매상사 간 변경(상품용)까지 모두
                  포함된 횟수로 제공
                </span>{' '}
                됩니다.
              </p>
              <Space height={13} />
              <BorderLine />
              <GridByFour>
                <GreyBox>변경 등록일</GreyBox>
                <GreyBox>변경 내역</GreyBox>
                <GreyBox>차량번호</GreyBox>
                <GreyBox>차량 용도</GreyBox>
              </GridByFour>
              {!isEmpty(insuranceData?.changeInfoHistory) &&
                insuranceData?.changeInfoHistory?.length > 0 &&
                insuranceData?.changeInfoHistory.map((item) => {
                  return (
                    <div key={item.changedAt}>
                      <GridByFour>
                        <Value isMobile>{item.changedAt ?? '-'}</Value>
                        <Value isMobile>{CHANGE_TYPE_KO[item.type] ?? '-'}</Value>
                        <Value isMobile>{item.carNum ?? '-'}</Value>
                        <Value isMobile>
                          {isEmpty(PURPOSE_KO[item.purpose]) &&
                            isEmpty(CARTYPE_KO[item.carType])
                            ? '-'
                            : `${PURPOSE_KO[item.purpose]} ${CARTYPE_KO[item.carType]
                            }`}
                        </Value>
                      </GridByFour>
                      <BorderLine />
                    </div>
                  );
                })}
            </Section>

            <Section ref={(el) => (sectionRef.current[5] = el)}>
              <Space height={48} />
              <SectionTitle>3. 보험사고 상세이력</SectionTitle>
              <Space height={12} />

              <GreyBlock padding={30}>
                <p className='black'>
                  차량이 자기차량손해담보에 가입하지 않은 동안에는
                </p>
                <p className='black'>
                  내 보험으로 처리한 사고이력정보의 제공이 불가능합니다.
                </p>
                <h6 style={{ marginTop: 10 }}>
                  미가입 기간 :{' '}
                  {isEmpty(insuranceData?.unregisteredPeriod) ||
                    insuranceData?.unregisteredPeriod?.length === 0 ? (
                    '-'
                  ) : (
                    <>
                      {insuranceData?.unregisteredPeriod?.map((period, idx) => {
                        const isLast =
                          idx === insuranceData?.unregisteredPeriod?.length - 1;
                        return (
                          <Fragment key={period}>
                            {period} {isLast ? '' : ', '}
                          </Fragment>
                        );
                      })}
                    </>
                  )}
                </h6>
              </GreyBlock>

              <Space height={24} />

              <p className='grey'>보험금 및 수리(견적)비 출처에 따라서</p>
              <p className='grey'>
                <span className='greyBold'>
                  '① 본인 자동차 보험에서 지급된 경우'
                </span>
                와{' '}
                <span className='greyBold'>
                  '② 타인의 자동차 보험에서 지급된 경우'
                </span>
                로 나뉘어 제공됩니다.{' '}
              </p>
              <p className='grey'>
                자동차사고로 상대 차량 또는 재물에 발생한 손해를 내 보험금에서
                지급된 경우의 정보를 제공합니다.
              </p>
              <p className='lightGrey' style={{ marginTop: 16, marginBottom: 24 }}>
                * 쌍방과실로 해당 자동차의 손상, 수리 기록이 내차 보험과 상대
                보험에서 동시에 처리된 경우에는 '내차 보험' 에만 표시되고 '상대
                보험'에서는 생략됩니다.
              </p>

              {isEmpty(insuranceData?.accidentHistory) ||
                insuranceData?.accidentHistory?.length === 0 ? (
                <>
                  <BorderLine />
                  <GreyBlock padding={20}>
                    <p className='lightGrey'>보험사고 이력이 없습니다.</p>
                  </GreyBlock>
                </>
              ) : (
                <>
                  <InsuranceWrapper>
                    {insuranceData?.accidentHistory.map(
                      (item: ACCIDENT_HISTORY) => {
                        return (
                          <div key={item.accidentAt} className='card'>
                            <SectionTitle
                              className='date'
                              style={{
                                marginBottom: openAccidentId?.includes(
                                  item.accidentAt,
                                )
                                  ? 18
                                  : 0,
                              }}
                              onClick={() => handleOpenAccident(item.accidentAt)}
                            >
                              <h5>{item.accidentAt}</h5>
                              <Icon
                                name={`pictogram-regular-arrow_${openAccidentId?.includes(item.accidentAt)
                                  ? 'up'
                                  : 'down'
                                  }`}
                              />
                            </SectionTitle>

                            {openAccidentId?.includes(item.accidentAt) && (
                              <>
                                <BorderLine />
                                {(!isEmpty(item.fromSelfInsurance) ||
                                  !isEmpty(item.fromAnotherInsurance)) && (
                                    <div className='content'>
                                      <h5 className='title'>내 차 사고</h5>

                                      {!isEmpty(item.fromSelfInsurance) &&
                                        calInsuranceCost(
                                          '내 차 보험 처리',
                                          item.fromSelfInsurance,
                                        )}

                                      {!isEmpty(item.fromAnotherInsurance) &&
                                        calInsuranceCost(
                                          '상대 보험 처리',
                                          item.fromAnotherInsurance,
                                        )}
                                    </div>
                                  )}

                                {!isEmpty(item.damageToOthers) && (
                                  <div className='content'>
                                    {(!isEmpty(item.fromSelfInsurance) ||
                                      !isEmpty(item.fromAnotherInsurance)) && (
                                        <>
                                          <BorderLine />
                                          <Space height={24} />
                                        </>
                                      )}

                                    <h5 className='title'>상대 차 사고</h5>
                                    {calInsuranceCost(
                                      '내 차 보험 처리',
                                      item.damageToOthers,
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      },
                    )}
                  </InsuranceWrapper>
                </>
              )}
              {(isEmpty(insuranceData?.accidentHistory) ||
                insuranceData?.accidentHistory?.length === 0) && (
                  <Space height={32} />
                )}
            </Section>

            <Section ref={(el) => (sectionRef.current[6] = el)}>
              <Space height={48} />
              <SectionTitle>4. 주행거리 이력</SectionTitle>
              <Space height={12} />

              <p className='grey'>
                카히스토리 주행거리이력 정보서비스는 자동차보험 가입자 또는 중고차
                판매자가 보험회사 또는 중고차쇼핑몰에 직접 제출한 주행거리자료를
                제공받아 확인절차 없이 그대로 제공하는 것이며, 개발원과 보험회사,
                중고차 쇼핑몰은 주행거리 정보의 정확성을 보장하지 아니하므로 차량을
                확인하기 위한 참고 정보로만 활용하시기 바랍니다.{' '}
                <span className='greyBold'>
                  해당 정보의 오류로 손실이 발생한 경우 개발원과 보험회사, 중고차
                  쇼핑몰은 이에 대한 책임을 지지 아니합니다.
                </span>
              </p>
              <Space height={24} />
              <BorderLine />

              {isEmpty(insuranceData?.odometerHistory) ||
                insuranceData?.odometerHistory?.length === 0 ? (
                <GreyBlock padding={20}>
                  <p className='lightGrey'>주행거리 이력이 없습니다.</p>
                </GreyBlock>
              ) : (
                <>
                  <GreyBlock
                    padding={19}
                    style={{
                      marginBottom: 4,
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <h6>
                      최근 주행거리{'  '}
                      {addComma(insuranceData?.odometerHistory[0]?.distance)} km
                    </h6>
                    {!isEmpty(insuranceData?.odometerHistory[0]) && (
                      <p className='grey' style={{ marginLeft: 10 }}>
                        {insuranceData?.odometerHistory[0].checkedAt} 기준
                      </p>
                    )}
                  </GreyBlock>
                  <BorderLine />

                  <GridByThree>
                    <GreyBox>날짜</GreyBox>
                    <GreyBox>주행거리</GreyBox>
                    <GreyBox>제공처</GreyBox>
                  </GridByThree>

                  {insuranceData?.odometerHistory.map((item) => {
                    return (
                      <GridByThree key={item.checkedAt}>
                        <MileageValue>{item.checkedAt}</MileageValue>
                        <MileageValue>{addComma(item.distance)}km</MileageValue>
                        <MileageValue>{item.inspector}</MileageValue>
                      </GridByThree>
                    );
                  })}
                </>
              )}
              <BorderLine />
            </Section>

            <Section ref={(el) => (sectionRef.current[7] = el)}>
              <Space height={48} />
              <SectionTitle>5. 차량가액 정보</SectionTitle>
              <Space height={12} />
              <BorderLine />
              <GreyBlock padding={20} style={{ marginBottom: 20 }}>
                <h6>
                  차량가액 범위{' '}
                  {!isEmpty(insuranceData?.carValue)
                    ? `${insuranceData.carValue} 만원`
                    : '-'}
                </h6>
                <p className='grey' style={{ marginTop: 6 }}>
                  * 동종 차량의 최소, 최대 금액 표시
                </p>
              </GreyBlock>
              <p className='grey' style={{ marginTop: 20, marginBottom: 20 }}>
                차량기준가액은 자동차보험 자차담보 가입 및 사고발생 시 손해액
                결정에 참고할 수 있는 자료로서, 시장에서 유통되는 중고차 시세와는
                무관함을 알려드리며 실제 자동차보험 계약체결 및 손해액 결정에
                적용여부가 다를 수 있습니다. (자세한 정보는 보험개발원 홈페이지에서
                조회 가능)
              </p>
              <BorderLine />
            </Section>

            <Section ref={(el) => (sectionRef.current[8] = el)}>
              <Space height={48} />
              <SectionTitle>6. 정보이용관련 유의사항</SectionTitle>
              <p className='red' style={{ marginTop: 24, marginBottom: 12 }}>
                * 자동차보험 사고기록이 없다고 해서 반드시 무사고라고 할 수는
                없습니다.
              </p>
              <p className='black'>
                중고차 사고이력정보 서비스는 자동차 보험을 취급하는 12개
                손해보험사의 자동차 보험수리 지급기록(1996년 이후)에
              </p>
              <p className='black'>
                근거하여 제공하고 있습니다. 따라서 다음과 같은 경우는 중고차
                이력정보 서비스를 제공할 수 없습니다.
              </p>

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <p className='grey'>
                  {`1) 사고가 있었다 하더라도 보험회사에서 사고신고를 하지 않고
                자비로 처리한 경우`}
                </p>
                <p className='grey'>
                  {`2) 사고신고를 하였더라도 면책, 취소 등의 사유로 지급되지 않은
                경우`}
                </p>
                <p className='grey'>{`3) 사고신고 후 자비로 처리한 경우`}</p>
                <p className='grey'>
                  {`4) 자동차보험이 아닌 운수 공제(택시공제, 화물공제, 버스공제
                등)에 가입되어 운수공제로부터 자동차의 피해에 대한 손해를
                보상받은 경우 등`}
                </p>
              </div>

              <p className='black'>
                본 중고차 사고이력 정보는 중고차 품질확인을 위한 보조정보로서
                자동차와 관련된 모든 사고의 발생 여부나 품질확인을 위한
              </p>
              <p className='black'>
                결정적인 판단자료로 사용 되어서는 아니 됩니다. 따라서 본 정보의
                확대해석이나 오·남용으로 발생하는 사항에 대해서
              </p>
              <p className='black'>
                보험개발원은 어떤 책임도 부담하지 아니합니다.
              </p>

              <p className='black' style={{ marginTop: 12 }}>
                보험개발원(www.kidi.or.kr)은 보험입법 제176조에 의하여
                설립된보험요율산출기관이며,
              </p>
              <p className='black'>
                중고차사고이력정보서비스(www.carhistory.or.kr)는보험업법시행령
                제86조 제1호에 근거하여 제공합니다.
              </p>
            </Section>
          </InsuranceContent>
        )}
        <Space height={20} />
        {/* <MoreBtnWrapper>
          <MoreBtn onClick={openInsuranceDetail}>
            <h5>{showInsurance ? '간략히 보기' : '보험이력 전체 보기'}</h5>
          </MoreBtn>
        </MoreBtnWrapper> */}
      </InsuranceSection>
    </>
  );
};

export default MobileCarInsurance;

const InsuranceSection = styled(MobileSection)`
  border: none;
`;

const InsuranceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-column-gap: 1px;
  background-color: #d5d8de;
`;

const MobileGrid = styled(InsuranceGrid)`
  grid-template-columns: 1fr 1fr;
`;

const InsuranceTwoCol = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #efefef;
  column-gap: 1px;

  .owner-history {
    ${NotoRegular}
    font-size:12px;
    height: 127px;
    ${FlexStyle}
    justify-content: center;
    background-color: #ffffff;
  }
`;

const CostValue = styled(Value)`
  flex-direction: column;
  height: 72px;

  ${NotoRegular}
  font-size:12px;
  line-height: 18px;
  text-align: center;
`;

const UnregisteredValue = styled(CostValue)``;

const Section = styled.div`
  scroll-margin-top: 70px;
`;

const Space = styled.div`
  height: ${({ height }) => height}px;
`;

const CountValue = styled(Value)`
  ${NotoRegular}
  font-size:12px;

  .none {
    ${NotoRegular}
    font-size:11px;
    color: ${({ theme }) => theme.base.grey400};
  }
`;

const InsuranceContent = styled.div`
  .red {
    ${NotoMedium}
    font-size:12px;
    color: ${({ theme }) => theme.primary};
    margin-top: 12px;
    margin-bottom: 13px;
  }

  .grey {
    ${NotoRegular}
    font-size:12px;
    line-height: 150%;
    color: ${({ theme }) => theme.base.grey600};
  }

  .lightGrey {
    ${NotoMedium}
    font-size:11px;
    line-height: 120%;
    color: ${({ theme }) => theme.base.grey400};
  }

  .black {
    ${NotoRegular}
    font-size:12px;
    line-height: 150%;
    color: ${({ theme }) => theme.base.black2};
  }

  .boldGrey {
    ${NotoBold}
    color: ${({ theme }) => theme.base.grey600};
  }
`;

const GridByThree = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #efefef;
  column-gap: 1px;

  > div {
    background-color: #ffffff;
  }

  .history {
    height: 100px;
    ${FlexStyle}
    flex-direction: column;
    justify-content: center;

    h6 {
      font-size: 12px;
    }
    .regular {
      ${NotoRegular}
      font-size:12px;
      margin-bottom: 20px;
    }
  }
`;

const GreyBlock = styled.div`
  width: 100%;
  padding: ${({ padding }) => padding}px;
  background-color: ${({ theme }) => theme.base.grey100};
  ${FlexStyle}
  flex-direction:column;

  h6 {
    font-size: 12px;
  }
`;

const MileageValue = styled(Value)`
  background-color: ${({ theme }) => theme.base.white};
  ${NotoMedium}
  font-size:12px;
  color: ${({ theme }) => theme.base.grey600};
`;

const InsuranceWrapper = styled.div`
  padding: 24px 20px;
  background-color: ${({ theme }) => theme.base.grey100};

  .card {
    padding: 18px 0;
    background-color: ${({ theme }) => theme.base.white};
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  .content {
    padding: 24px 16px 0 16px;

    .title {
      margin-bottom: 17px;
    }
  }

  .date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
`;

const InsuranceCase = styled.div`
  margin-bottom: 21px;
`;

const Total = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  p {
    ${NotoRegular}
    font-size: 11px;
    color: ${({ theme }) => theme.base.grey800};
  }

  h5 {
    ${NotoBold}
    font-size: 12px;
    color: ${({ theme }) => theme.base.grey900};
  }
`;

const InsuranceGrey = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.base.grey100};
  padding: 13px 20px;
  margin-bottom: 8px;
  position: relative;

  div {
    text-align: center;

    p:nth-child(1) {
      ${NotoRegular}
      font-size:10px;
      line-height: 12px;
      color: ${({ theme }) => theme.base.grey600};
      margin-bottom: 6px;
    }
  }

  .black {
    ${NotoMedium}
    font-size:10px;
    text-align: center;
    color: #242424;
  }
`;

const Detail = styled.p`
  ${NotoRegular}
  font-size:11px;
  line-height: 16px;
  color: ${({ theme }) => theme.base.grey600};
`;

const GridBlock = styled.div``;

const SmallGreyBox = styled(GreyBox)`
  height: 36px;
`;

const GridByFour = styled(Grid)`
  background-color: #d5d8de;
  column-gap: 1px;
  row-gap: 1px;
`;
