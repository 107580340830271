import Script from 'next/script';

interface GAHeadProps {
  eventCode?: string;
}

const GAHead = ({ eventCode }: GAHeadProps) => {
  if (process.env.NODE_ENV !== 'production') {
    return <></>;
  }
  const GA_ID = 'G-95SJEM8G35';

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_ID}', {
            page_path: window.location.pathname,
          });
          ${eventCode}
        `,
        }}
      />
    </>
  );
};
export default GAHead;
