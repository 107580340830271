import { getWindowDimension } from '@service/utils/getWindowDimestion';
import { isMobile } from '@service/utils/isMobile';
import { useEffect, useState } from 'react';

export const useIsMobile = () => {
  const [isMobileDevice, setisMobileDevice] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      const { width } = getWindowDimension();
      if (width > 0) setisMobileDevice(isMobile(width));
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobileDevice;
};
