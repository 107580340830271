import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { theme } from '@styles/global';
import { useOutsideClick } from '@service/hooks/useOutsideClick';
import { getWindowDimension } from '@service/utils/getWindowDimestion';
import Icon from './Icon';
import media from '@styles/responsive';
import { useIsMobile } from '@service/hooks/useIsMobile';

interface ModalProps {
  fullHeight?: boolean;
  isShowTopBtn?: boolean;
  title?: string;
  description?: string;
  children?: ReactNode;
  leftButtonPressed?: () => void;
  leftButtonTitle?: string;
  rightButtonPressed?: () => void;
  rightButtonTitle?: string;
}

type DIMENSION = {
  width: number;
  height: number;
};

const Modal = forwardRef((props: ModalProps, ref) => {
  const isMobile = useIsMobile();
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [maxDimension, setMaxDimension] = useState<DIMENSION>({
    width: 0,
    height: 0,
  });

  const modalRef = useRef<React.RefObject<HTMLElement>>(null);
  useOutsideClick(modalRef, () => close());

  const {
    fullHeight = false,
    isShowTopBtn = false,
    title,
    description,
    children,
    leftButtonTitle,
    leftButtonPressed,
    rightButtonPressed,
    rightButtonTitle,
  } = props;

  useEffect(() => {
    setPortalRoot(document.getElementById('modal'));
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.style.position = 'relative';
      document.body.style.overflow = 'hidden';

      const handleResize = () => {
        const { height, width } = getWindowDimension();

        if (height > 0 && width > 0) {
          setMaxDimension({ width, height }); // 임의로 정해둔 비율
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [visible]);

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
    leftButtonPressed?.();
    document.body.style.position = 'unset';
  };

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    []
  );

  const isButtonExist = (buttonPosition: 'left' | 'right') => {
    if (buttonPosition === 'left') {
      return !!leftButtonTitle;
    }
    return !!rightButtonTitle;
  };

  const isHasNoBtn = !isButtonExist('left') && !isButtonExist('right');

  if (!portalRoot) return <></>;

  if (portalRoot)
    return createPortal(
      visible ? (
        <BackDrop>
          <Wrapper>
            {isShowTopBtn && (
              <CancelIcon>
                <Icon
                  name="pictogram-regular-input_delete"
                  width={47}
                  height={47}
                />
              </CancelIcon>
            )}

            <PopUpContainer
              ref={modalRef}
              width={maxDimension?.width}
              height={
                fullHeight
                  ? maxDimension?.height * 0.9
                  : maxDimension?.height * 0.7
              }
            >
              <ContentContainer isMobile={isMobile}>
                {title ? <TitleTX>{title}</TitleTX> : <VoidSpace />}
                {!!description && <DescriptionTX>{description}</DescriptionTX>}
                {!!children && <Center>{children}</Center>}
              </ContentContainer>
              {!isHasNoBtn && <HorizontalDivider />}
              <ButtonContainer>
                {isButtonExist('left') && (
                  <LeftButton
                    isUsedSole={isButtonExist('right')}
                    onClick={() => {
                      close();

                      leftButtonPressed && leftButtonPressed();
                    }}
                  >
                    <ButtonTX color={theme.base.grey800}>
                      {leftButtonTitle}
                    </ButtonTX>
                  </LeftButton>
                )}
                {isButtonExist('left') && <VerticalDivider />}
                {isButtonExist('right') && (
                  <RightButton
                    isUsedSole={isButtonExist('left')}
                    onClick={() => {
                      close();

                      rightButtonPressed && rightButtonPressed();
                    }}
                  >
                    <ButtonTX isBold>{rightButtonTitle}</ButtonTX>
                  </RightButton>
                )}
              </ButtonContainer>
            </PopUpContainer>
          </Wrapper>
        </BackDrop>
      ) : (
        <></>
      ),
      portalRoot
    );
  return <></>;
});
export default Modal;

const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0px;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 900;
`;

const Wrapper = styled.div``;

const PopUpContainer = styled.div`
  background-color: ${({ theme }) => theme.base.white};
  min-width: 288px;
  max-height: ${({ height }) => height}px;
  overflow-y: auto;
  border-bottom-width: 0;
  border-radius: 4px;
  width: auto;
`;

const CancelIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

const ContentContainer = styled.div`
  padding: ${({ isMobile }) => (isMobile ? '0 20px' : '0 30px')};
`;

const TitleTX = styled.h5`
  font-size: 16px;
  text-align: center;
  padding: 22px 0px 12px 0px;
`;

const DescriptionTX = styled.p`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.base.grey800};
  padding: 10px 0px 20px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Button = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0px;
`;

interface SoleButtonStyle {
  isUsedSole: boolean;
}

const LeftButton = styled(Button)<SoleButtonStyle>`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: ${({ isUsedSole }) => (isUsedSole ? 0 : 4)}px;
`;
const RightButton = styled(Button)<SoleButtonStyle>`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: ${({ isUsedSole }) => (isUsedSole ? 0 : 4)}px;
`;

const ButtonTX = styled.p`
  font-weight: ${({ isBold }) => isBold && 'bold'};
  font-size: 14px;
  color: ${({ color, theme }) => color || theme.base.black};
`;

const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.base.grey200};
`;

const HorizontalDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.base.grey200};
`;

const VoidSpace = styled.div`
  width: 100%;
  height: 16px;
`;
