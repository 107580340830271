import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  getFirstRegDate,
} from '@components/carfax/CarDetailContent';
import { SpecProps } from '@components/carfax/CarDetailContent';
import { addComma } from '@service/utils/number';
import { isEmpty } from '@service/utils/validation';
import {
  BorderLine,
  Flex,
  GreyBox,
  MaxWidth,
  Section,
  Value,
} from '@styles/carFaxResultStyle';
import Skeleton from '@components/common/Skeleton';
import { NotoRegular } from '@styles/global';

const Spec = ({ specData, isSpecRefetching, odometer }: SpecProps) => {

  const [mission, setMission] = useState<string>('');
  const [shape, setShape] = useState<string>('');

  useEffect(() => {
    if (specData?.carOptions?.length > 0) {
      specData?.carOptions.forEach((option) => {
        if (option.parts === '미션') {
          setMission(option.optionName);
        }
        if (option.parts === '바디') {
          setShape(option.optionName);
        }
      });
    }
  }, [specData]);

  const isLoading = isSpecRefetching;

  if (isLoading)
    return (
      <Section>
        <Skeleton width='100%' height={166} />
      </Section>
    );

  return (
    <Section>
      <BorderLine color='#8C8F99' />
      <SpecGrid>
        <Flex>
          <GreyCol>년형</GreyCol>
          <GreyValue>
            {isEmpty(specData?.carSpec?.saleYear)
              ? '-'
              :
              specData?.carSpec?.saleYear}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>최초 등록일</GreyCol>
          <GreyValue>
            {!isEmpty(specData?.carSpec?.carRegistrationDate) ? (
              <>
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.yyyy}
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.mm
                  ? `.${getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.mm}`
                  : ''}
                {getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.dd
                  ? `.${getFirstRegDate(String(specData?.carSpec?.carRegistrationDate))?.dd}`
                  : ''}
              </>
            ) : (
              '-'
            )}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>외형</GreyCol>
          <GreyValue>
            {isEmpty(shape) ? '-' : shape}
          </GreyValue>
        </Flex>
      </SpecGrid>
      <SpecGrid>
        <Flex>
          <GreyCol>미션</GreyCol>
          <GreyValue>
            {isEmpty(mission) ? '-' : mission}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>연료</GreyCol>
          <GreyValue>
            {' '}
            {isEmpty(specData?.carSpec?.fuel)
              ? '-'
              : specData?.carSpec?.fuel}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>배기량</GreyCol>
          <GreyValue>
            {!isEmpty(specData?.carSpec?.engineSize)
              ? `${addComma(specData?.carSpec?.engineSize)}cc`
              : '-'}
          </GreyValue>
        </Flex>
      </SpecGrid>

      <SpecGrid>
        <Flex>
          <GreyCol>색상</GreyCol>
          <GreyValue>
            {!isEmpty(specData?.carSpec?.color)
              ? specData?.carSpec?.color
              : '-'}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>주행거리</GreyCol>
          <GreyValue>
            {odometer === 0 ? '-' : `${addComma(Number(odometer))}km`}
            {/* {!isEmpty(insuranceData?.odometerHistory[0]?.distance)
              ? `${addComma(insuranceData?.odometerHistory[0]?.distance)}km`
              : '-'} */}
          </GreyValue>
        </Flex>
        <Flex>
          {/* <GreyCol>용도</GreyCol>
          <GreyValue>
            -
          </GreyValue> */}
          <GreyCol>차대번호</GreyCol>
          <GreyValue>
            {isEmpty(specData?.carSpec?.vin)
              ? '-'
              : specData?.carSpec?.vin}
          </GreyValue>
        </Flex>
      </SpecGrid>
      {/* <SpecGrid grid='1fr 2fr'>
        <Flex>
          <GreyCol>차대번호</GreyCol>
          <GreyValue>
            {isEmpty(specData?.carSpec?.vin)
              ? '-'
              : specData?.carSpec?.vin}
          </GreyValue>
        </Flex>
        <Flex>
          <GreyCol>검사유효기간</GreyCol>
          <GreyValue>
            -
          </GreyValue>
        </Flex>
      </SpecGrid> */}
      {/* <BorderLine /> */}
    </Section>
  );
};

export default Spec;

const SpecGrid = styled.div`
  display: grid;
  width: ${MaxWidth}px;
  grid-template-columns: ${({ grid }) => grid || '1fr 1fr 1fr'};
`;

const GreyCol = styled(GreyBox)`
  background-color: #757575;
  width: 157px;
  min-width: 157px;
  max-width: 157px;
  height: 100%;
  color: #ffffff;
  ${NotoRegular}
  border-bottom:1px solid #888888;
`;

const GreyValue = styled(Value)`
  ${NotoRegular}
  color:#757575;
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #dddddd;
`;
