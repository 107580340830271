import styled, { css } from 'styled-components';
import { B18, M12, NotoBold, NotoMedium, NotoRegular } from './global';

export const MaxWidth = 1040;

export const FlexStyle = `
    display:flex;
    align-items:center;
`;

export const CarSpecification = styled.div`
  margin-bottom: 48px;
`;

export const Title = styled.h4`
  ${B18}
  color: ${({ theme }) => theme.base.black2};
  margin-bottom: 17px;

  .red {
    ${B18}
    color:#ef3333;
  }
`;

export const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ color }) => color || '#d5d8de'};
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 4px;
`;

export const GreyBox = styled.div`
  ${FlexStyle};
  justify-content: center;
  width: 100%;
  padding: 15px;
  background: #757575;
  ${NotoRegular}
  font-size:12px;
  color: #ffffff;

  .sub {
    ${NotoMedium}
    font-size:11px;
    margin-left: 6px;
    color: ${({ theme }) => theme.base.grey400};
  }
`;

export const Value = styled.div`
  ${FlexStyle};
  padding: 15px;
  background: ${({ theme }) => theme.base.white};
  display: flex;
  align-items: center;
  justify-content: center;
  ${NotoRegular}
  font-size:12px;
  color: #757575;
  word-break: break-all;

  ${({ isMobile }) =>
    isMobile &&
    css`
      ${NotoRegular}
      font-size:11px;
    `}
`;

export const GreyLine = styled(GreyBox)`
  padding: 13px 10px;
  justify-content: flex-start;
  height: 36px;

  .date {
    ${M12}
    height:100%;
    width: 160px;
    color: ${({ theme }) => theme.base.grey600};
  }
  .article {
    ${M12}
    height:100%;
    background-color: #ffffff;
    color: #0067df;
  }
`;

// 개편된 스타일

export const Section = styled.div`
  margin-bottom: 72px;
`;

export const SectionTitle = styled.p`
  ${NotoRegular}
  font-size:17px;
  letter-spacing: -2%;
  color: #757575;
  margin-bottom: 12px;

  .red {
    ${NotoRegular}
    font-size:17px;
    color: ${({ theme }) => theme.primary};
  }

  .bold {
    ${NotoBold}
    color:#121212;
  }
`;

export const MobileTitle = styled.h3`
  ${NotoBold}
  font-size:16px;
  letter-spacing: -2%;
  color: #242424;
  margin-bottom: 20px;

  .red {
    ${NotoBold}
    font-size:16px;
    color: ${({ theme }) => theme.primary};
  }
`;

export const Flex = styled.div`
  ${FlexStyle}

  ${({ apart }) =>
    apart &&
    css`
      justify-content: space-between;
    `}
`;

export const BlackBorderLine = styled(BorderLine)`
  background-color: #232323;
`;

export const Empty = styled.div`
  padding: 20px;
  ${NotoRegular}
  font-size: 14px;
`;

export const MoreBtnWrapper = styled.div`
  ${FlexStyle}
  justify-content: center;
`;

export const MoreBtn = styled.button`
  border-radius: 4px;
  width: 334px;
  height: 38px;
  background-color: #1ac7ff;
  ${FlexStyle}
  justify-content: center;

  h5 {
    font-size: 13px;
    color: #ffffff;
  }
`;

export const MobileSection = styled.div`
  padding: 36px 20px;
  border-bottom: 8px solid #f7f7f7;
`;

export const Space = styled.div`
  height: ${({ h }) => h}px;
`;
