const hasWindow = typeof window !== 'undefined';

const getWindowDimension = () => {
  let height = 0;
  let width = 0;

  if (hasWindow) {
    height = window.innerHeight;
    width = window.innerWidth;
  }
  return { height, width };
};

export { getWindowDimension };
