import React from 'react';
import styled from 'styled-components';
import { isEmpty } from '@service/utils/validation';
import { useCheckRegister } from '@service/query/useSearch';
import { ContentProps, getFirstRegDate } from './CarDetailContent';
import {
  BorderLine,
  Empty,
  GreyBox,
  Grid,
  Section,
  SectionTitle,
  Value,
} from '@styles/carFaxResultStyle';
import { NotoRegular } from '@styles/global';
import Skeleton from '@components/common/Skeleton';
import useUserStore from '@store/userStore';

const CarSeizure = ({ carNo, ownerName, transactionId }: ContentProps) => {
  const { token } = useUserStore();

  const { data, isLoading, isRefetching, isError } = useCheckRegister({
    carNo,
    ownerName,
    transactionId,
    token,
  });

  const seizureData = data?.contents;

  const pawnCnt = seizureData?.filter((d) => d?.type === '저당')?.length || 0;
  const seizureCnt = seizureData?.filter((d) => d?.type === '압류')?.length || 0;

  if ((isLoading || isRefetching) && !isEmpty(ownerName))
    return (
      <Container>
        <SectionTitle>
          저당 {pawnCnt} / 압류 {seizureCnt}
        </SectionTitle>
        <Skeleton width='100%' height={160} />
      </Container>
    );

  if (!isLoading && isError)
    return (
      <Container>
        <SectionTitle>
          저당 <span className='bold'>{pawnCnt}</span> / 압류{' '}
          <span className='bold'>{seizureCnt}</span>
        </SectionTitle>
        <BorderLine />
        <Empty>저당/압류 내역을 불러올 수 없습니다.</Empty>
      </Container>
    );

  return (
    <Container>
      <SectionTitle>
        저당 <span className='bold'>{pawnCnt}</span> / 압류{' '}
        <span className='bold'>{seizureCnt}</span>
      </SectionTitle>
      <BorderLine />

      {isEmpty(seizureData) || seizureData?.length === 0 ? (
        <Empty>저당/압류 내역을 불러올 수 없습니다.</Empty>
      ) : (
        <>
          <SeizureGrid>
            <SmallGreyBox>구분</SmallGreyBox>
            <SmallGreyBox>등록일자</SmallGreyBox>
            <SmallGreyBox>관리번호</SmallGreyBox>
            <SmallGreyBox>내용</SmallGreyBox>
          </SeizureGrid>

          {seizureData?.map((content) => {
            return (
              <>
                <SeizureGrid key={content?.docNumber}>
                  <SeizureValue>{content?.type}</SeizureValue>
                  <SeizureValue>
                    {!isEmpty(content?.registerDate) && (
                      <>
                        {getFirstRegDate(content?.registerDate)?.yyyy}
                        {getFirstRegDate(content?.registerDate)?.mm
                          ? `.${getFirstRegDate(content?.registerDate)?.mm}`
                          : ''}
                        {getFirstRegDate(content?.registerDate)?.dd
                          ? `.${getFirstRegDate(content?.registerDate)?.dd}`
                          : ''}
                      </>
                    )}
                  </SeizureValue>
                  <SeizureValue>{content?.docNumber}</SeizureValue>
                  <SeizureValue isWide>
                    {!isEmpty(content?.contents) &&
                      content?.contents?.split('\n')?.map((line) => {
                        return <p>· {line}</p>;
                      })}
                  </SeizureValue>
                </SeizureGrid>
                <BorderLine />
              </>
            );
          })}
          <p className='notice'>
            제공되는 정보는 매매시 참고용으로만 활용하세요. 실 차량매입 시 정확한
            등급을 확인하시길 바랍니다.
          </p>
        </>
      )}
    </Container>
  );
};

export default CarSeizure;

const Container = styled(Section)`
  .notice {
    ${NotoRegular}
    font-size:11px;
    color: ${({ theme }) => theme.primary};
    margin-top: 16px;
    margin-right: 18px;
    text-align: right;
  }
`;

const SeizureGrid = styled(Grid)`
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  background-color: #d5d8de;
  column-gap: 1px;
`;

const SeizureValue = styled(Value)`
  ${NotoRegular}
  font-size:12px;
  color: #555555;
  align-items: flex-start;
  line-height: ${({ isWide }) => (isWide ? '180%' : '120%')};
  flex-direction: column;

  p {
    ${NotoRegular}
    font-size:12px;
    color: #555555;
    width: 100%;
    text-align: left;
  }
`;

const SmallGreyBox = styled(GreyBox)`
  padding: 0;
  height: 36px;
`;
