import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';

type GUIDE_TYPE = 'none' | 'info' | 'question';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix?: string;
  guide?: string;
  guideType?: GUIDE_TYPE;
  errorMessage?: string;
  isError?: boolean;
  clear?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    id,
    suffix,
    guide,
    guideType = 'info',
    errorMessage,
    isError = false,
    clear = () => {},
    onFocus,
    onBlur,
    ...otherProps
  } = props;

  const [focused, setFocused] = useState(false);

  const onFocusHandler = (e: React.FocusEventHandler<HTMLInputElement>) => {
    setFocused(true);

    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  const onBlurHandler = (e: React.FocusEventHandler<HTMLInputElement>) => {
    setFocused(false);

    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };
  return (
    <Container>
      <Label isActive={focused}>
        <InputContainer>
          <StyledInput
            id={id}
            ref={ref}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            {...otherProps}
          />

          <SubComponentContainer>
            {suffix && <Suffix>{suffix}</Suffix>}
            {/* {focused && otherProps.value && otherProps.value.length > 0 && (
              <ClearButton
                onClick={clear}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Icon name="pictogram-regular-input_delete_line" />
              </ClearButton>
            )} */}
          </SubComponentContainer>
        </InputContainer>

        {/* <UnderLineBorder isActive={focused} isError={isError} /> */}

        {guide && !isError && (
          <UnderInputContainer>
            {guideType !== 'none' && (
              <Icon name={`pictogram-small-${guideType}_filled`} />
            )}
            <GuideText>{guide}</GuideText>
          </UnderInputContainer>
        )}

        {errorMessage && isError && (
          <UnderInputContainer>
            <Icon name="pictogram-small-input_info_false" />
            <ErrorText>{errorMessage}</ErrorText>
          </UnderInputContainer>
        )}
      </Label>
    </Container>
  );
});

export default Input;

interface ActiveStyle {
  isActive: boolean;
  isError: boolean;
}

const Container = styled.div``;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;

  margin-bottom: 4px;
  color: ${(props) => props.theme.base.grey600};

  :focus-within {
    color: ${(props) => props.theme.base.black};
  }
`;

const Suffix = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: right;

  color: ${(props) => props.theme.base.grey400};
`;

const StyledInput = styled.input`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  text-align: left;
  border: none;
  padding: 8px 0px;
  width: 100%;
  caret-color: ${(props) => props.theme.base.black};

  ::placeholder {
    font-family: SpoqaHanSans-Medium;
    width: fit-content;
    color: ${(props) => props.theme.base.grey400};
  }
`;

const UnderLineBorder = styled.div<ActiveStyle>`
  height: 1px;
  width: 100%;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(to right, ${
          props.isError
            ? `${props.theme.primary} 50%`
            : `${props.theme.base.black} 50%`
        }, ${props.theme.base.white} 50%)`
      : props.theme.base.grey250};

  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;

  ${(props) =>
    props.isActive &&
    css`
      background-position: left bottom;
    `};
`;

const UnderInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

const GuideText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-left: 4px;

  color: ${(props) => props.theme.base.grey400};
`;

const ErrorText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-left: 4px;
  color: ${(props) => props.theme.primary};
`;

const SubComponentContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
`;

const ClearButton = styled.button`
  cursor: pointer;
`;
