/**
 * Null Check Util Function
 * @param value
 * @returns {boolean}
 */
export function isEmpty(value: any) {
  if (value === null) return true;
  if (value === 'null') return true;
  if (typeof value === 'undefined') return true;
  if (value === 'undefined') return true;

  if (typeof value === 'object') {
    if (JSON.stringify(value) === '{}' || JSON.stringify(value) === '[]') {
      return true;
    }
    if (!value) {
      return true;
    }
  } else if (
    typeof value === 'string' &&
    (value.trim() === '' || value.trim() === "''")
  ) {
    return true;
  } else if (Array.isArray(value) && value.length < 1) {
    return true;
  } else if (typeof value === 'number') {
    if (Number.isNaN(value)) {
      return true;
    }
  }

  return false;
}
